/* Main Container */
.cs-overview {
  min-height: 100vh;
  padding-bottom: 160px;
}

/* Test Promo Section */
.cs-overview__section:nth-child(1) {
  margin-top: 24px;
}

/* Remove all extra padding from Ant tabs containers */
.ant-tabs-content.ant-tabs-content-top {
  padding: 0 !important;
}

.ant-tabs-content-holder {
  padding: 0 !important;
}

.ant-tabs-tabpane {
  padding: 0 !important;
}

/* Target the specific div causing extra padding */
div[class*='ant-tabs-content ant-tabs-content-top ant-tabs-content-animated'] {
  padding: 0 !important;
  margin: 0 !important;
}

.ant-layout.caresync-container,
.ant-layout-content {
  padding: 0 !important;
  margin: 0 !important;
}

/* Section Layout */
.cs-overview__section {
  margin-bottom: 24px;
  position: relative;
  z-index: 1;
  padding: 0 16px;
}

/* Global Card Styles */
.cs-overview .ant-card {
  border-radius: 8px;
  backdrop-filter: blur(10px);
  border: none !important;
}

/* Global Typography Overrides */
.cs-overview .ant-typography {
  margin: 0 !important;
}


/* Global Button Overrides */
.cs-overview .ant-btn-primary {
  background: #1890ff !important;
  border-color: #1890ff !important;
}

.cs-overview .ant-btn-primary:hover {
  background: #40a9ff !important;
  border-color: #40a9ff !important;
}

.cs-overview .ant-btn-link {
  color: #4cc9f0 !important;
}

.cs-overview__get-started-btn {
  transition: all 0.3s ease;
}

.cs-overview__get-started-btn:hover {
  border-color: #40c9ff !important;
  color: #40c9ff !important;
}

/* Fixed Button Styling */
.cs-overview__fixed-button-wrapper {
  position: fixed;
  bottom: 80px; /* Adjust based on your navbar height */
  left: 0;
  right: 0;
  padding: 0 16px;
  z-index: 100;
  pointer-events: none; /* Allow scrolling through the wrapper */
}

.cs-overview__fixed-get-started-btn.ant-btn {
  width: 100%;
  height: 56px;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  background: transparent !important;
  border: 1.5px solid #10b2e1;
  color: #10b2e1;
  box-shadow: 0 0 10px rgba(16, 178, 225, 0.2);
  pointer-events: auto; /* Re-enable button clicks */
  transition: all 0.3s ease;
}

.cs-overview__fixed-get-started-btn.ant-btn:hover {
  background: transparent !important;
  border-color: #40c9ff;
  color: #40c9ff;
  box-shadow: 0 0 15px rgba(16, 178, 225, 0.3);
}

/* Welcome Modal Styles */
.welcome-modal .ant-modal-content {
  border-radius: 12px;
  padding: 20px;
}

.welcome-modal__content {
  text-align: center;
  max-height: 80vh;
}

.welcome-features {
  text-align: left;
  margin: 16px 0;
}

.welcome-features .ant-typography {
  margin-bottom: 12px !important;
  font-size: 14px;
}

.ai-suggestions-section {
  padding: 16px;
  border-radius: 8px;
  margin: 16px 0;
}

.suggestions-wrapper {
  max-height: 200px;
  overflow-y: auto;
  margin-top: 12px;
}

.welcome-modal .ant-btn-primary {
  height: 40px;
  font-size: 16px;
  margin-top: 12px;
}

.welcome-modal .ant-typography {
  margin-bottom: 8px !important;
}

/* Add custom scrollbar for the suggestions wrapper */
.suggestions-wrapper::-webkit-scrollbar {
  width: 6px;
}

.suggestions-wrapper::-webkit-scrollbar-track {
  border-radius: 3px;
}

.health-warnings {
  margin-top: 16px;
}

.warning-card.ant-card {
  transition: all 0.3s ease;
}

.warning-card.ant-card:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(255, 77, 79, 0.15);
}

/* Coming Soon Banner */
.coming-soon-banner {
  width: 100%;
  margin: 0;
  padding: 0 16px;
  backdrop-filter: blur(8px);
  border-radius: 12px;
  overflow: hidden;
}

/* Bottom Actions Section */
.cs-overview__bottom-actions {
  position: sticky;
  bottom: 0;
  z-index: 10;
  padding: 8px !important;
  margin-bottom: 8px !important;
  display: flex;
  gap: 6px;
}

.cs-overview__bottom-actions:last-child {
  margin-bottom: 80px !important;
}

/* Base button styles */
.cs-overview__action-btn.ant-btn {
  flex: 1;
  min-width: 0;
  width: 50% !important;
  height: 44px !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 4px !important;
  white-space: nowrap !important;
  line-height: 1 !important;
  border: none !important;
  text-overflow: ellipsis;
  overflow: hidden;
  background: #1890ff !important;
}

.cs-overview__action-btn.ant-btn:hover:not(:disabled) {
  background: #40a9ff !important;
}

.cs-overview__action-btn.ant-btn:disabled {
  background: rgba(24, 144, 255, 0.7) !important;
  cursor: not-allowed;
  opacity: 0.85;
}

/* Coming soon badge specific styles */
.cs-overview .cs-overview__bottom-actions .coming-soon-badge {
  font-size: 10px;
  opacity: 0.8;
  margin-left: 4px;
}

/* Coming Soon Animation Section */
.cs-overview__animation-container {
  margin: 0 16px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 16px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  backdrop-filter: blur(8px);
  border: 1px solid rgba(101, 210, 216, 0.15);
  position: relative;
  width: calc(100% - 32px);
}

.cs-overview__animation-container > div {
  width: 100% !important;
  max-width: 300px !important;
}

.cs-overview__coming-soon-text {
  margin-top: 16px !important;
  text-align: center;
  font-size: 13px;
  color: rgba(28, 37, 54, 0.65);
  max-width: 280px;
  line-height: 1.5;
}
