.mobile-camera-capture {
  width: 100%;
  position: fixed;
  bottom: 80px;
  left: 0;
  right: 0;
  padding: 0 16px;
  z-index: 100;
  background: transparent;
}

/* Button styling to exactly match Edit Profile */
.action-button {
  width: 100% !important;
  flex: 1;
  height: 48px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin: 0 0 12px 0 !important;
  padding: 0 16px !important;
  border-radius: 8px !important;
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1px solid #10B2E1 !important;
  color: #10B2E1 !important;
}

.action-button:hover,
.action-button:focus {
  background: rgba(16, 178, 225, 0.2) !important;
  border-color: #10B2E1 !important;
  color: #10B2E1 !important;
}

.action-button svg {
  font-size: 16px !important;
  margin-right: 8px !important;
}

/* Hide file inputs */
.file-input,
input[type="file"] {
  display: none;
}
 