.ai-insights-step {
  padding: 0 !important;
}

.mock-ai-card.ant-card {
  margin: 16px;
  background: rgba(101, 210, 216, 0.1);
  border: 1px solid rgba(101, 210, 216, 0.2);
  border-radius: 8px;
}

.ai-message {
  display: flex;
  gap: 16px;
  align-items: flex-start;
}

.ai-icon {
  font-size: 24px;
  color: var(--caresync-secondary);
}

.ai-content {
  flex: 1;
}

.ai-recommendations {
  list-style: none;
  padding: 0;
  margin: 12px 0 0;
}

.ai-recommendations li {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  margin-bottom: 8px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
}

.ai-recommendations li svg {
  color: var(--caresync-secondary);
  font-size: 14px;
} 