.macro-new-entry-block {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  transition: 250ms ease;
  cursor: pointer;
}
.macro-new-entry-block:hover {
  box-shadow: 0px 0px 10px 0px rgb(0, 128, 0, 0.4);
  border-color: green;
}
.macro-new-entry-block > * {
  font-size: 30px;
  transition: 250ms ease;
  color: green !important;
}
.macro-new-entry-block:hover > * {
  transform: scale(1.1);
}
.image-responsive {
  width: 80%;
  max-width: 300px;
  display: block;
  margin: 0 auto;
  border: 1px solid #333;
  border-radius: 5px;
}

@media (min-width: 1024px) {
  .image-responsive {
    width: 80%;
    max-width: 300px;
  }
}

.macro-rings {
  position: relative;
  min-height: calc(100vh - 65px);
  padding: 16px;
  padding-bottom: 180px; /* Space for buttons */
  overflow-y: auto;
}

/* Content container */
.rings-card {
  width: 100%;
  border: 1px solid rgba(16, 178, 225, 0.15) !important;
  padding: 24px !important;
}

/* Button container */
.rings-button-container {
  width: 100%;
  position: fixed;
  bottom: 80px;
  left: 0;
  right: 0;
  padding: 0 16px;
  z-index: 100;
  background: transparent;
}

/* iOS safe area support */
@supports (padding-bottom: env(safe-area-inset-bottom)) {
  .macro-rings {
    padding-bottom: calc(180px + env(safe-area-inset-bottom));
  }

  .rings-button-container {
    bottom: calc(80px + env(safe-area-inset-bottom));
  }
}

/* Ensure content is scrollable on mobile */
@media screen and (max-width: 768px) {
  .macro-rings {
    height: calc(100vh - 65px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .rings-card {
    margin-bottom: 120px;
  }
}

/* Info columns layout */
.info-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 12px;
  background: rgba(16, 178, 225, 0.05);
  border-radius: 8px;
}

.info-column strong {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 4px;
  font-size: 14px;
}

.info-column p {
  color: white;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}
