/* RULER GRAPHIC */
.ruler-graphic-custom-container {
  width: 175px;
  height: 75px;
  max-width: 100%;
  background: #eaf8fb;
  border-radius: 12px;
  position: relative;
}
.ruler-graphic-custom-line-container {
  height: 30px;
  float: right;
  width: 70%;
  padding: 8px 8px;
  left: 0;
  top: 5px;
  width: 100%;
  display: flex;
  justify-content: right;
  gap: 10px;
  position: absolute;
}
.ruler-graphic-custom-line-container > *:first-child,
.ruler-graphic-custom-line-container > *:last-child {
  opacity: 0.3;
}
.ruler-graphic-custom-line-container > *:nth-child(2),
.ruler-graphic-custom-line-container > *:nth-child(8) {
  opacity: 0.45;
}
.ruler-graphic-custom-line-container > *:nth-child(3),
.ruler-graphic-custom-line-container > *:nth-child(7) {
  opacity: 0.6;
}
.ruler-graphic-custom-reg-line {
  height: 10px;
  width: 2px;
  background: black;
}
.ruler-graphic-custom-active-line {
  height: 20px;
  width: 2px;
  background: red;
}
.ruler-graphic-custom-reading {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.ruler-graphic-custom-label {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

/* BMI GRAPHIC */
.custom-bmi-scale-container {
  width: 100%;
  position: relative;
}
.custom-bmi-scale-bar {
  background: linear-gradient(to right, #b0d6ee 0%, #8ae2df 20%, #96e1c8 60%, #d7d592 80%, #e5b686 90%, #e3808d 100%);
  width: 100%;
  height: 20px;
  border-radius: 10px;
}

.custom-bmi-scale-labels-container {
  margin-top: 6px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.custom-bmi-scale-indicator {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: red;
  margin-bottom: 6px;
  position: relative;
}
.custom-bmi-scale-labels {
  font-size: 12px;
}
