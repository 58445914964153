.promo-carousel {
  position: relative;
  padding: 0;
  margin: 0 16px;
}

.promo-slide {
  height: 160px;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  background: white;
}

.promo-slide img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
  border-radius: 16px;
}

.promo-indicators {
  display: flex;
  justify-content: center;
  gap: 6px;
  margin-top: 12px;
}

.indicator {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  transition: all 0.3s ease;
}

.indicator.active {
  width: 16px;
  border-radius: 3px;
  background: #10B2E1;
} 