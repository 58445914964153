.test-detail {
  max-width: 800px;
  margin: 0 auto;
  padding: 16px 24px;
  width: 100%;
  box-sizing: border-box;
}

.test-detail-content {
  width: 100%;
}

.test-detail-section {
  margin-bottom: 32px;
}

.test-detail-section .ant-typography {
  color: rgba(255, 255, 255, 0.85);
  margin-bottom: 16px;
}

.test-detail-divider.ant-divider {
  margin: 24px 0;
  border-color: rgba(255, 255, 255, 0.1);
}

@media (max-width: 768px) {
  .test-detail {
    padding: 16px;
  }
}
