.todays-patients-item-container {
  transition: 250ms ease;
  padding: 16px;
  border-radius: 12px;
  cursor: pointer;
}
.todays-patients-item-container.active {
  background-color: rgba(218, 243, 250, 0.54) !important;
}

.todays-patients-item-container:hover {
  background-color: rgba(218, 243, 250, 0.54) !important ;
}
