.mob-allergies-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  position: relative;
  overflow: hidden;
}

.allergy-section-content {
  flex: 1;
  padding: 8px 0;
  padding-bottom: 120px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 0;
}

/* Header Styling */
.section-header {
  text-align: center;
  margin-bottom: 0;
  padding: 12px;
  animation: fadeIn 0.3s ease-out;
  border: 1px solid rgba(16, 178, 225, 0.15);
  border-radius: 16px;
}

.section-header h2 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 8px;
  line-height: 1.2;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.section-header h2 .section-icon {
  font-size: 20px;
  color: #10b2e1;
}

.section-header p {
  font-size: 15px;
  margin: 0;
  line-height: 1.4;
}

/* Common Allergies Grid */
.allergies-grid {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
  padding: 0 4px;
}

.allergy-card {
  width: 100%;
  border: 1px solid rgba(16, 178, 225, 0.15);
  border-radius: 16px;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
  min-height: 76px;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

.allergy-card:hover {
  background: rgba(16, 178, 225, 0.05);
  border-color: rgba(16, 178, 225, 0.3);
  transform: translateY(-1px);
}

.allergy-card:active {
  transform: scale(0.98);
}

.allergy-card::before {
  content: '';
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  border: 2px solid rgba(16, 178, 225, 0.4);
  border-radius: 6px;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.allergy-card::after {
  content: '✓';
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  opacity: 0;
  transition: all 0.2s ease;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.allergy-card[aria-checked='true'] {
  background: rgba(16, 178, 225, 0.15);
  border-color: #10b2e1;
}

.allergy-card[aria-checked='true']::before {
  background: #10b2e1;
  border-color: #10b2e1;
}

.allergy-card[aria-checked='true']::after {
  opacity: 1;
}

.allergy-icon {
  width: 40px;
  height: 40px;
  background: rgba(16, 178, 225, 0.1);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #10b2e1;
  font-size: 18px;
  flex-shrink: 0;
}

.allergy-name {
  font-size: 16px;
  font-weight: 500;
  margin-right: 40px;
  flex: 1;
}

/* Additional Section */
.additional-section {
  animation: slideUp 0.3s ease-out;
  margin-top: 24px;
  padding: 0 4px;
  padding-bottom: 60px;
}

.section-subtitle {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
  padding: 0 12px;
}

.section-subtitle svg {
  color: #10b2e1;
}

/* Search Section */
.search-section {
  border: 1px solid rgba(16, 178, 225, 0.15);
  border-radius: 16px;
  padding: 20px;
}

.allergy-search {
  width: 100%;
}

/* Tags */
.allergy-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
  min-height: 32px;
  padding: 0 4px;
}

.allergy-tag {
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1px solid rgba(16, 178, 225, 0.2) !important;
  border-radius: 24px !important;
  padding: 6px 16px !important;
  font-size: 14px !important;
  height: auto !important;
  line-height: 1.4 !important;
}

/* Action Buttons */
.mobile-form-actions {
  position: fixed;
  bottom: 50px;
  left: 0;
  right: 0;
  padding: 0 16px 12px;
  background: transparent;
  z-index: 100;
}

.mobile-form-actions > * {
  pointer-events: auto;
}

/* Update button container and button styles */

.cancel-button,
.save-button {
  margin: 0 !important;
  padding: 0 16px !important;
  height: 48px !important;
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border-radius: 8px !important;
}

/* Update button styles for side-by-side layout */
.action-button {
  width: 100% !important; /* Make button fill its container */
  height: 48px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin: 0 !important;
  padding: 0 16px !important;
  border-radius: 8px !important;
  position: relative;
  overflow: hidden;
}

.action-button.save {
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1px solid #10b2e1 !important;
  color: #10b2e1 !important;
}

.action-button.back {
  background: rgba(233, 30, 99, 0.1) !important;
  border: 1px solid #e91e63 !important;
  color: #e91e63 !important;
}

.action-button.back:hover {
  background: rgba(233, 30, 99, 0.15) !important;
}

.action-button.transparent {
  background: transparent !important;
  border: 1px solid rgba(16, 178, 225, 0.3) !important;
  color: #10b2e1 !important;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Update button styles to handle loading state better */
.action-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Category Selection Styles */
.allergy-categories {
  padding: 4px 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.category-grid {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0;
  animation: fadeIn 0.3s ease-out;
}

.category-card {
  background: linear-gradient(145deg, rgba(var(--category-color), 0.15) 0%, rgba(var(--category-color), 0.05) 100%);
  border: 1px solid rgba(var(--category-color), 0.2);
  border-radius: 16px;
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
  min-height: 80px;
  margin: 0;
}

.category-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(145deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.category-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(var(--category-color), 0.15);
}

.category-card:hover::before {
  opacity: 1;
}

.category-card:active {
  transform: scale(0.98);
}

.category-icon {
  display: none;
}

.category-content {
  flex: 1;
  position: relative;
  padding-right: 24px;
}

.category-content h3 {
  color: rgb(var(--category-color));
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 6px;
  letter-spacing: -0.3px;
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: left;
}

.category-content h3 svg {
  font-size: 18px;
  color: rgb(var(--category-color));
  width: 20px;
  flex-shrink: 0;
}

.category-content p {
  font-size: 12px;
  margin: 0;
  line-height: 1.3;
  text-align: left;
  padding-left: 30px;
}

/* Arrow indicator */
.category-card::after {
  content: '→';
  position: absolute;
  right: 20px;
  bottom: 20px;
  font-size: 18px;
  color: rgba(var(--category-color), 0.5);
  transition: all 0.3s ease;
}

.category-card:hover::after {
  transform: translateX(4px);
  color: rgba(var(--category-color), 0.8);
}

/* Update the ALLERGY_CATEGORIES in the JS file */

/* Coming Soon Section */
.coming-soon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px 20px;
  animation: fadeIn 0.3s ease-out;
}

.coming-soon svg {
  font-size: 48px;
  color: var(--category-color);
  margin-bottom: 24px;
}

.coming-soon h3 {
  font-size: 24px;
  margin: 0 0 12px;
}

.coming-soon p {
  margin: 0 0 24px;
}

.back-button {
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1px solid #10b2e1 !important;
  color: #10b2e1 !important;
  height: 44px;
  padding: 0 24px;
  border-radius: 8px;
  font-weight: 500;
}

/* Add these styles for the input and tags */
.input-with-button {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
}

.allergy-input {
  flex: 1;
  border: 1px solid rgba(16, 178, 225, 0.2) !important;
  border-radius: 12px !important;
  height: 52px !important;
  padding: 0 20px !important;
  font-size: 16px !important;
}

.allergy-tag {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1px solid rgba(16, 178, 225, 0.2) !important;
  border-radius: 24px !important;
  padding: 6px 12px 6px 16px !important;
  font-size: 14px !important;
  height: auto !important;
  line-height: 1.4 !important;
}

.delete-tag {
  padding: 4px !important;
  margin-left: 4px !important;
  font-size: 12px !important;
  transition: all 0.2s ease;
}

.delete-tag:hover {
  color: #ff4d4f !important;
}

/* Remove the standalone icon styles */
.section-header > .section-icon {
  display: none;
}

/* Update button styles to match MobileEditMode */
.cancel-button {
  background: rgba(233, 30, 99, 0.1);
  border: 1px solid #e91e63;
  color: #e91e63;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
}

.save-button {
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1px solid #10b2e1 !important;
  color: #10b2e1 !important;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
}

/* Remove old button styles */
.action-button.save,
.action-button.back {
  display: none;
}

.dark-modal .ant-modal-content {
  border-radius: 16px;
  border: 1px solid rgba(16, 178, 225, 0.15);
}

.dark-modal .ant-modal-header {
  border-bottom: 1px solid rgba(16, 178, 225, 0.15);
}

.dark-modal .ant-modal-footer {
  border-top: 1px solid rgba(16, 178, 225, 0.15);
}

.dark-modal .ant-btn {
  background: rgba(16, 178, 225, 0.1);
  border: 1px solid #10b2e1;
  color: #10b2e1;
}

.dark-modal .ant-btn:hover {
  background: rgba(16, 178, 225, 0.15);
}
