.mobile-edit-container {
  padding-top: 0; /* Remove any top padding */
  height: 100%;
  overflow-y: auto;
}

.mobile-edit-header {
  background-color: transparent; /* Remove the blue background */
  padding: 16px;
  position: sticky;
  top: 0;
  z-index: 1;
}

.mobile-edit-header h2 {
  margin: 0;
  font-size: 20px;
}

.back-button {
  background: none;
  border: none;
  color: #10b2e1;
  font-size: 20px;
  padding: 8px;
  cursor: pointer;
}

.mobile-edit-content {
  flex: 1;
  padding: 16px;
  padding-bottom: 200px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
}

.form-section {
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 16px;
  position: relative;
  border: 1px solid rgba(16, 178, 225, 0.3);
}

.form-section::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 16px;
  z-index: 0;
  filter: blur(2px);
}

.form-section::after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.form-section > * {
  position: relative;
  z-index: 2;
}

.form-section h3 {
  color: #10b2e1;
  font-size: 16px;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
}

.form-actions {
  padding: 16px;
  display: flex;
  gap: 16px;
  position: fixed;
  bottom: 70px;
  left: 0;
  right: 0;
  z-index: 100;
  height: 80px;
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.2);
}

.form-actions button {
  flex: 1;
  height: 48px;
  border-radius: 8px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cancel-button {
  background: rgba(255, 255, 255, 0.1);
}

.save-button {
  background: #10b2e1;
}

.save-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Scrollbar styling */
.mobile-edit-content::-webkit-scrollbar {
  width: 4px;
}

.mobile-edit-content::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

.mobile-edit-content::-webkit-scrollbar-thumb {
  background: rgba(16, 178, 225, 0.5);
  border-radius: 2px;
}

/* Google Address Input styling */
.mobile-edit-container .google-places-autocomplete {
  width: 100%;
}

.mobile-edit-container .google-places-autocomplete__input {
  border: 1px solid rgba(16, 178, 225, 0.15) !important;
  border-radius: 8px !important;
  padding: 8px 12px !important;
  height: 44px !important;
  width: 100% !important;
}

.mobile-edit-container .google-places-autocomplete__input:focus {
  border-color: rgba(16, 178, 225, 0.4) !important;
  box-shadow: 0 0 0 1px rgba(16, 178, 225, 0.1), 0 0 8px rgba(16, 178, 225, 0.15) !important;
  background: #243247 !important;
}

/* Dropdown styling */
.mobile-edit-container .google-places-autocomplete__dropdown-container {
  border: 1px solid rgba(16, 178, 225, 0.15) !important;
  border-radius: 8px !important;
}

.mobile-edit-container .google-places-autocomplete__suggestion-item {
  padding: 12px !important;
}

.mobile-edit-container .google-places-autocomplete__suggestion-item--active {
  background: rgba(16, 178, 225, 0.1) !important;
}

/* Google Address Dropdown styling */
.mobile-edit-container .google-places-autocomplete__dropdown {
  border: 1px solid rgba(16, 178, 225, 0.15) !important;
  border-radius: 8px !important;
  margin-top: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2) !important;
}

.mobile-edit-container .google-places-autocomplete__suggestion {
  padding: 12px !important;
  border-bottom: 1px solid rgba(16, 178, 225, 0.1);
}

.mobile-edit-container .google-places-autocomplete__suggestion:hover,
.mobile-edit-container .google-places-autocomplete__suggestion--active {
  background: rgba(16, 178, 225, 0.1) !important;
}

.mobile-edit-container .google-places-autocomplete__suggestion:last-child {
  border-bottom: none;
}

/* Target the Google Places input specifically */
.mobile-edit-container .pac-container {
  border: 1px solid rgba(16, 178, 225, 0.15) !important;
  border-radius: 8px !important;
  margin-top: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2) !important;
}

.mobile-edit-container .pac-item {
  border-color: rgba(16, 178, 225, 0.1) !important;
  padding: 8px !important;
}

.mobile-edit-container .pac-item:hover {
  background-color: rgba(16, 178, 225, 0.1) !important;
}

/* Style the Google Places dropdown */
.pac-container {
  border: 1px solid rgba(16, 178, 225, 0.15) !important;
  margin-top: 4px;
  border-radius: 8px;
}

.pac-item {
  border-color: rgba(16, 178, 225, 0.1) !important;

  padding: 8px;
}

.pac-item:hover {
  background-color: rgba(16, 178, 225, 0.1) !important;
}

.pac-matched {
  color: #10b2e1 !important;
}

.pac-icon {
  filter: invert(1);
}

/* Global Google Places styles - move these outside the .mobile-edit-container scope */
.pac-container {
  border: 1px solid rgba(16, 178, 225, 0.15) !important;
  border-radius: 8px !important;
  margin-top: 4px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2) !important;
  z-index: 1500 !important; /* Ensure it's above other elements */
}

.pac-item {
  border-color: rgba(16, 178, 225, 0.1) !important;
  padding: 8px !important;
}

.pac-item:hover {
  background-color: rgba(16, 178, 225, 0.1) !important;
}

.pac-icon {
  filter: invert(1) !important;
}

.mobile-edit-buttons {
  display: flex;
  gap: 12px;
  padding: 16px;
  position: fixed;
  bottom: 65px;
  left: 0;
  right: 0;
  background: transparent;
  z-index: 1000;
  margin: 0;
}

.mobile-edit-buttons .cancel-button {
  background: rgba(233, 30, 99, 0.1);
  border: 1px solid #e91e63;
  color: #e91e63;
}

.mobile-edit-buttons .cancel-button:hover,
.mobile-edit-buttons .cancel-button:focus {
  background: rgba(233, 30, 99, 0.2) !important;
  border-color: #e91e63 !important;
  color: #e91e63 !important;
}

.mobile-edit-buttons .save-button {
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1px solid #10b2e1 !important;
  color: #10b2e1 !important;
}

.mobile-edit-buttons .save-button:hover,
.mobile-edit-buttons .save-button:focus {
  background: rgba(16, 178, 225, 0.2) !important;
  border-color: #10b2e1 !important;
  color: #10b2e1 !important;
}

.mobile-edit-container form {
  margin-bottom: 0;
}

/* Add margin to the last form section to ensure spacing */
.form-section:last-child {
  margin-bottom: 65px;
}

/* Add these styles to make form labels visible */

.mobile-edit-container .ant-form-item-required::before {
  color: #ff4d4f !important;
}

/* Input prefix icons */
.mobile-edit-container .ant-input-prefix {
  color: rgba(16, 178, 225, 0.8) !important;
  margin-right: 8px !important;
}

.mobile-edit-container .ant-picker-suffix {
  color: rgba(16, 178, 225, 0.8) !important;
}

/* Radio buttons */
.mobile-edit-container .ant-radio-button-wrapper {
  border-color: rgba(16, 178, 225, 0.3) !important;
}

.mobile-edit-container .ant-radio-button-wrapper:hover {
  color: #10b2e1 !important;
}

.mobile-edit-container .ant-radio-button-wrapper-checked {
  background: rgba(16, 178, 225, 0.1) !important;
  border-color: #10b2e1 !important;
  color: #10b2e1 !important;
}

/* Fix double border on inputs with prefix */
.mobile-edit-container .ant-input-affix-wrapper {
  padding: 0 11px;

  border: 1px solid rgba(16, 178, 225, 0.15) !important;
  border-radius: 8px !important;
}

.mobile-edit-container .ant-input-affix-wrapper > input.ant-input {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  padding: 8px 0;
}

.mobile-edit-container .ant-input-affix-wrapper:focus,
.mobile-edit-container .ant-input-affix-wrapper-focused {
  border-color: #10b2e1 !important;
  box-shadow: 0 0 0 2px rgba(16, 178, 225, 0.1) !important;
}

/* Phone number input specific fix */
.mobile-edit-container .ant-input-group-wrapper {
  border: 1px solid rgba(16, 178, 225, 0.15) !important;
  border-radius: 8px !important;
}

.mobile-edit-container .ant-input-group-wrapper .ant-input-wrapper {
  background-color: transparent !important;
}

.mobile-edit-container .ant-input-group-wrapper .ant-input-group-addon {
  background-color: transparent !important;
  border: none !important;
}

.mobile-edit-container .ant-input-group-wrapper .ant-input {
  border: none !important;
  background-color: transparent !important;
}

.profile-image-container {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
}

.profile-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;
}
