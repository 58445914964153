.cs-overview__action-strip {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 16px;
  border-radius: 12px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.cs-overview__action-button {
  flex: 1;
  height: auto !important;
  padding: 12px 8px !important;
  border: none !important;
  background: transparent !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  transition: transform 0.2s ease;
}

.cs-overview__action-button:hover {
  transform: translateY(-2px);
}

.cs-overview__action-icon {
  width: 44px;
  height: 44px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.cs-overview__action-label {
  font-size: 13px !important;
  text-align: center;
  font-weight: 500 !important;
}
