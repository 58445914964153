.plan-header {
  padding: 16px;
  position: relative;
  z-index: 2;
}

.plan-stats {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.streak-banner {
  display: flex;
  align-items: center;
  gap: 8px;
  background: rgba(255, 119, 31, 0.1);
  padding: 8px 16px;
  border-radius: 20px;
  color: #FF771F;
  font-weight: 500;
}

.streak-icon {
  font-size: 16px;
}

.plan-stats-right {
  display: flex;
  align-items: center;
  gap: 16px;
}

.total-points {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #FFC107;
  font-weight: 500;
}

.points-icon {
  font-size: 16px;
}

.points-count {
  position: relative;
  transition: transform 0.2s ease;
}

.points-count.animate {
  transform: scale(1.2);
  color: #FFD700;
}

.leaderboard-button {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 36px;
  padding: 0 16px;
  background: rgba(16, 178, 225, 0.1);
  border: 1px solid rgba(16, 178, 225, 0.2);
  color: #10B2E1;
}

.leaderboard-button:hover {
  background: rgba(16, 178, 225, 0.15);
  border-color: rgba(16, 178, 225, 0.3);
  color: #10B2E1;
} 