.mobile-macro-graphs {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 150px 16px 152px !important;
  min-height: 100vh;

  touch-action: pan-y pinch-zoom;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
}

.graphs-header {
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: transparent !important;
  padding: 16px;
  padding-top: 96px;
  z-index: 10;
  transform: translateY(0);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform;
}

.graphs-header.hidden {
  transform: translateY(-100%);
}

.back-button {
  display: none;
}

/* Date Picker Styles */
.date-picker {
  width: 100%;
}

/* View Mode Toggle Base Styles */
.view-mode-toggle {
  background: rgba(0, 230, 255, 0.05) !important;
  border: 2px solid rgba(0, 230, 255, 0.6) !important;
  border-radius: 8px !important;
  padding: 4px !important;
  width: 100% !important;
  height: 44px !important;
}

/* Label styles */
.view-mode-toggle .segmented-label {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px !important;
  color: rgba(0, 230, 255, 0.8) !important;
  font-weight: 500 !important;
}

.view-mode-toggle .segmented-label svg {
  font-size: 18px !important;
}

/* Macro Card Header Styles */
.macro-card .macro-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  text-align: center;
  width: 100%;
}

.macro-card .macro-header h3 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 230, 255, 0.9) !important;
  font-weight: 600;
  font-size: 16px;
  margin: 0;
  width: 100%;
}

/* Position icon to the left of text */
.macro-card .macro-header h3 span {
  order: 2;
  margin-left: 8px;
}

.macro-card .macro-icon {
  order: 1;
  font-size: 18px;
  filter: drop-shadow(0 0 8px rgba(0, 230, 255, 0.6));
}

/* Combined Graph Title */
.combined-graph-card h3 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: rgba(0, 230, 255, 0.9) !important;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 16px;
  text-align: center;
  width: 100%;
}

/* Card backgrounds */
.macro-card,
.combined-graph-card {
  background: rgba(0, 230, 255, 0.02) !important;
  border: 1px solid rgba(0, 230, 255, 0.1) !important;
}

/* Individual graph colors */
.macro-card[data-macro='protein'] .macro-header h3,
.macro-card[data-macro='protein'] .macro-icon {
  color: #ff8000 !important;
  filter: drop-shadow(0 0 8px rgba(255, 128, 0, 0.6));
}

.macro-card[data-macro='carbs'] .macro-header h3,
.macro-card[data-macro='carbs'] .macro-icon {
  color: #3498db !important;
  filter: drop-shadow(0 0 8px rgba(52, 152, 219, 0.6));
}

.macro-card[data-macro='fats'] .macro-header h3,
.macro-card[data-macro='fats'] .macro-icon {
  color: #2ecc71 !important;
  filter: drop-shadow(0 0 8px rgba(46, 204, 113, 0.6));
}

.macro-card[data-macro='sugar'] .macro-header h3,
.macro-card[data-macro='sugar'] .macro-icon {
  color: #ff1493 !important;
  filter: drop-shadow(0 0 8px rgba(255, 20, 147, 0.6));
}

/* Bottom button container */
.bottom-button-container {
  position: fixed;
  bottom: 72px;
  left: 0;
  right: 0;
  padding: 16px;
  z-index: 99;
}

/* Back button styles */
.bottom-back-button {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  background: rgba(0, 230, 255, 0.1) !important;
  border: 2px solid rgba(0, 230, 255, 0.5) !important;
  color: rgba(0, 230, 255, 0.9) !important;
  font-weight: 600;
  transition: all 0.2s ease;
}

.bottom-back-button:hover {
  background: rgba(0, 230, 255, 0.2) !important;
  border-color: rgba(0, 230, 255, 0.7) !important;
  transform: translateY(-1px);
}

/* Graph container spacing */
.individual-graphs {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 40px;
}
