.daily-tips-modal .tips-content {
  /* ... */
}

.daily-tips-modal .tips-header {
  padding: 16px 20px;
  border-bottom: 1px solid rgba(16, 178, 225, 0.15);
}

.daily-tips-modal .doctor-section {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.daily-tips-modal .doctor-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(16, 178, 225, 0.05);
  border: 2px solid #10b2e1;
  flex-shrink: 0;
}

.daily-tips-modal .doctor-avatar svg {
  font-size: 20px;
  color: #10b2e1;
  filter: drop-shadow(0 0 8px rgba(16, 178, 225, 0.3));
}

.daily-tips-modal .doctor-info {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.daily-tips-modal .doctor-info h3 {
  color: #fff;
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.daily-tips-modal .doctor-info p {
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.daily-tips-modal .tip-card {
  margin: 12px 8px;
  border: 1px solid rgba(16, 178, 225, 0.15) !important;
  border-radius: 12px !important;
  padding: 12px !important;
}

.daily-tips-modal .tip-category {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #10b2e1;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 8px;
}

.daily-tips-modal .tip-content {
  position: relative;
  padding: 8px 0;
}

.daily-tips-modal .quote-icon {
  position: absolute;
  top: 0;
  left: -4px;
  color: rgba(16, 178, 225, 0.1);
  font-size: 36px;
  z-index: 0;
}

.daily-tips-modal .tip-content h4 {
  color: #fff;
  margin: 0 0 6px;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  padding-left: 4px;
}

.daily-tips-modal .tip-content p {
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
  font-size: 14px;
  line-height: 1.4;
  position: relative;
  padding-left: 4px;
}

.daily-tips-modal .tip-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  padding-top: 10px;
  border-top: 1px solid rgba(16, 178, 225, 0.15);
}

.daily-tips-modal .tip-indicator {
  display: flex;
  gap: 8px;
}

.daily-tips-modal .tips-navigation-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(16, 178, 225, 0.2);
  transition: all 0.3s ease;
}

.daily-tips-modal .tips-navigation-dot.active {
  background: #10b2e1;
  transform: scale(1.2);
  filter: drop-shadow(0 0 6px rgba(16, 178, 225, 0.5));
}

.daily-tips-modal .tips-footer {
  padding: 16px;
  display: flex;
  justify-content: center;
}

/* Ant Design Button Overrides */
.daily-tips-modal .ant-btn {
  background: rgba(16, 178, 225, 0.1);
  border-color: rgba(16, 178, 225, 0.15);
  color: rgba(255, 255, 255, 0.8);
}

.daily-tips-modal .ant-btn:hover {
  background: rgba(16, 178, 225, 0.2);
  border-color: #10b2e1;
  color: #fff;
}

.daily-tips-modal .ant-btn-primary {
  background: #10b2e1;
  border-color: #10b2e1;
  color: #fff;
}

.daily-tips-modal .ant-btn-primary:hover {
  background: #0c8eb3;
  border-color: #0c8eb3;
}

/* Modal Overrides */
.daily-tips-modal .ant-modal-content {
  border: 1px solid rgba(16, 178, 225, 0.15);
}

.daily-tips-modal .ant-modal-close {
  color: rgba(255, 255, 255, 0.7);
}

.daily-tips-modal .ant-modal-close:hover {
  color: #fff;
}

/* Update mobile margins to be even smaller */
@media (max-width: 480px) {
  .daily-tips-modal .tip-card {
    margin: 16px 4px;
  }
}

/* Navigation button styles */
.daily-tips-modal .tip-footer .ant-btn {
  padding: 8px;
  height: auto;
  min-width: 32px;
}

.daily-tips-modal .tip-footer .ant-btn svg {
  color: rgba(255, 255, 255, 0.7);
  transition: color 0.3s ease;
}

.daily-tips-modal .tip-footer .ant-btn:hover svg {
  color: #10b2e1;
}

.daily-tips-modal .tip-footer .ant-btn:active svg,
.daily-tips-modal .tip-footer .ant-btn:focus svg {
  color: #10b2e1;
}

/* Footer button styling */
.daily-tips-modal .tips-footer .ant-btn-primary {
  min-width: 160px;
  height: 40px;
  padding: 0 24px;
  font-weight: 500;
  border: 1.5px solid rgba(16, 178, 225, 0.4) !important;
  border-radius: 8px;
  transition: all 0.3s ease;
  color: #10b2e1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.daily-tips-modal .tips-footer .ant-btn-primary:hover {
  background: rgba(16, 178, 225, 0.1) !important;
  border-color: rgba(16, 178, 225, 0.6) !important;
  transform: translateY(-1px);
}

.daily-tips-modal .tips-footer .ant-btn-primary:active {
  transform: translateY(0);
}

.daily-tips-modal .tips-footer .ant-btn-primary svg {
  font-size: 14px;
  color: #10b2e1;
}
