/* CareSync Colors */
:root {
  --caresync-primary: #1890ff;
  --caresync-secondary: #65d2d8;
}

.welcome-modal .ant-modal-content {
  border-radius: 16px;
  border: 1px solid rgba(101, 210, 216, 0.2);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.6);
  background: '#fff' !important;
  padding: 0 !important;
  position: relative;
  z-index: 1000;
}

.welcome-modal__content {
  min-height: 520px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tour-content {
  flex: 1;
  padding: 8px 12px;
  overflow-y: auto;
  position: relative;
  z-index: 1;
}

/* Welcome Step */
.tour-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  animation: fadeSlideIn 0.3s ease-out;
  padding: 0 8px;
}

.ai-animation {
  margin: -8px 0 16px;
  height: 140px;
}

/* Test Menu Step */
.screen-preview {
  border-radius: 12px;
  padding: 20px;
  margin: 20px 0;
  min-height: 300px;
}

.mock-test-categories {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.mock-category {
  padding: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.mock-category svg {
  color: #1890ff;
  font-size: 24px;
}

/* AI Insights Step */
.mock-ai-interface {
  padding: 20px;
  border-radius: 12px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.feature-icon {
  color: #1890ff;
  font-size: 32px;
}

.mock-recommendation {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* Results Step */
.mock-result-item {
  padding: 16px;
  border-radius: 12px;
}

.mock-result-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

/* Navigation */
.modal-navigation {
  padding: 12px 16px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  border-top: 1px solid rgba(101, 210, 216, 0.2);
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.nav-button {
  min-width: 120px !important;
  height: 40px !important;
  font-size: 16px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px !important;
  border-radius: 6px !important;
  padding: 0 16px !important;
}

.next-button.ant-btn-primary {
  background: var(--caresync-secondary) !important;
  border-color: var(--caresync-secondary) !important;
  color: #000 !important;
  font-weight: 500 !important;
}

/* Add step indicators */
.step-indicators {
  display: flex;
  justify-content: center;
  gap: 8px;
  padding: 12px 0;
  border-top: 1px solid rgba(101, 210, 216, 0.1);
}

.step-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.step-dot.active {
  background: var(--caresync-secondary);
  transform: scale(1.2);
}

/* Typography */
.welcome-modal h3.ant-typography {
  font-size: 22px;
  margin-bottom: 12px;
}

.feature-description {
  font-size: 15px;
  line-height: 1.5;
  max-width: 95%;
  margin: 0 auto 20px;
}

/* Animations */
@keyframes fadeSlideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Add new styles for mock metrics */
.mock-metrics {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
}

.mock-metric {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-radius: 6px;
}

/* Update feature styles */
.welcome-features {
  width: 100%;
  margin-top: 16px;
}

.feature-item {
  padding: 12px;
  border: 1px solid rgba(101, 210, 216, 0.3);
  border-radius: 8px;
  transition: all 0.3s ease;
  width: 100%;
  background: none;
}

.feature-item:hover {
  border-color: rgba(101, 210, 216, 0.6);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(101, 210, 216, 0.1);
}

.feature-text {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.feature-text .ant-typography strong {
  font-size: 16px;
  letter-spacing: 0.3px;
}

.feature-text .ant-typography:not(strong) {
  font-size: 14px;
  opacity: 0.8;
  letter-spacing: 0.2px;
}

/* Update welcome step styles */
.welcome-step {
  text-align: center;
  padding: 0 16px;
}

.welcome-step h3.ant-typography {
  font-size: 24px;
  margin-bottom: 10px;
  padding-top: 8px;
  background: linear-gradient(135deg, #000, #65d2d8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature-description {
  font-size: 15px;
  line-height: 1.5;
  max-width: 95%;
  margin: 0 auto 16px;
}

.welcome-features {
  width: 100%;
  margin-top: 16px;
}

.feature-item {
  padding: 12px;
  border: 1px solid rgba(101, 210, 216, 0.3);
  border-radius: 8px;
  transition: all 0.3s ease;
  width: 100%;
  background: none;
}

.feature-item:hover {
  border-color: rgba(101, 210, 216, 0.6);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(101, 210, 216, 0.1);
}

.highlight-feature {
  border-color: rgba(101, 210, 216, 0.5);
  background: linear-gradient(135deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.05));
}

.feature-text {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.feature-text .ant-typography strong {
  font-size: 17px;
  letter-spacing: 0.3px;
  margin-bottom: 2px;
}

.feature-text .ant-typography:not(strong) {
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.2px;
}
