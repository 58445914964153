.payment-receipt-container {
  min-height: calc(100vh - 120px);
  padding: 16px 16px 80px 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.payment-receipt-container .receipt-content {
  width: 100%;
  max-width: 600px;
  margin-top: -20px;
}

.payment-receipt-container .receipt-card {
  background: rgba(20, 24, 36, 0.95);
  border: 1px solid rgba(16, 178, 225, 0.15);
  border-radius: 12px;
  padding: 32px;
  width: 100%;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  margin-bottom: 24px;
}

.payment-receipt-container .receipt-card h2 {
  color: #fff;
  font-size: 24px;
  margin: 0 0 24px 0;
  font-weight: 600;
  text-align: center;
}

.payment-receipt-container .receipt-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(16, 178, 225, 0.15);
}

.payment-receipt-container .receipt-header h2 {
  color: #fff;
  font-size: 24px;
  margin: 0;
  font-weight: 600;
}

.payment-receipt-container .receipt-actions {
  position: fixed;
  bottom: 70px;
  left: 0;
  right: 0;
  display: flex;
  gap: 12px;
  justify-content: center;
  padding: 16px;
  background: transparent !important;
  border-top: none;
  z-index: 100;
}

.payment-receipt-container .action-button {
  background: transparent !important;
  border: 1px solid rgba(16, 178, 225, 0.3) !important;
  color: #10b2e1 !important;
  height: 40px;
  padding: 0 24px !important;
  font-weight: 500 !important;
  transition: all 0.3s ease !important;
  box-shadow: none !important;
}

.payment-receipt-container .action-button:hover {
  border-color: #10b2e1 !important;
  transform: translateY(-1px);
}

.payment-receipt-container .receipt-section {
  background: rgba(16, 178, 225, 0.05);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.payment-receipt-container .section-title {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.payment-receipt-container .detail-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 4px 0;
}

.payment-receipt-container .detail-row:last-child {
  margin-bottom: 0;
}

.payment-receipt-container .detail-label {
  font-size: 14px;
}

.payment-receipt-container .detail-value {
  font-size: 14px;
  font-family: monospace;
}

.payment-receipt-container .total-row {
  border-top: 1px solid rgba(16, 178, 225, 0.15);
  margin-top: 12px;
  padding-top: 12px;
}

.payment-receipt-container .total-row .detail-label,
.payment-receipt-container .total-row .detail-value {
  font-weight: 600;
}

.payment-receipt-container .merchant-info {
  text-align: center;
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid rgba(16, 178, 225, 0.15);
  font-size: 12px;
}

.payment-receipt-container .success-status {
  color: #52c41a !important;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .payment-receipt-container {
    padding-bottom: 100px;
  }

  .payment-receipt-container .receipt-actions {
    padding: 12px;
  }

  .payment-receipt-container .action-button {
    padding: 0 16px !important;
  }
}
