.checkmark-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.checkmark {
  width: 150px;
  height: 150px;
  display: block;
}

.checkmark__check {
  stroke-width: 3;
  stroke: #00b33c;
  stroke-linecap: round;
  stroke-linejoin: round;
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: checkmark-draw 0.8s cubic-bezier(0.65, 0, 0.45, 1) forwards,
             checkmark-fade 0.5s ease-out 1s forwards;
}

@keyframes checkmark-draw {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes checkmark-fade {
  to {
    opacity: 0;
  }
} 