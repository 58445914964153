#video-box {
  position: static;
  top: 0px;
}

#video-container.container-fixed {
  height: 100px !important;
}

#video-box.video-fixed {
  position: fixed;
  top: 88px;
  right: 24px;
  width: 500px !important;
  border-radius: 24px;
  border: 5px white solid;
  overflow: hidden;
  height: 300px !important;
  z-index: 10000000;
}
#video-box.video-fixed > * > * > * > * > *:first-child {
  width: 500px !important;
  height: 200px !important;
}
#video-box.draggable {
  cursor: grab;
}
#video-box.draggable:active {
  cursor: grabbing;
}
#video-box.video-minimized {
  display: none;
}

/* ========
  TABS 
======== */
