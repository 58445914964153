.specimen-modal-wrapper .ant-modal-content {
  background: rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(12px);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.6);
}

.specimen-info-modal {
  padding: 20px;
  color: white;
  max-height: 80vh;
  overflow-y: auto;
}

.specimen-info-modal h3 {
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 500;
}

.specimen-info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
}

.specimen-info-row {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}

.specimen-code {
  font-weight: 500;
  color: #65D2D8;
  min-width: 50px;
  font-size: 16px;
  padding: 4px 8px;
  background: rgba(101, 210, 216, 0.1);
  border-radius: 4px;
  text-align: center;
}

.specimen-desc {
  color: rgba(255, 255, 255, 0.85);
  font-size: 14px;
  flex: 1;
}

@media (max-width: 768px) {
  .specimen-info-grid {
    grid-template-columns: 1fr;
  }
} 