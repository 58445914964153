.ql-editor {
  min-height: 600px; /* Set your desired minimum height */
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
    left: 0;
  }
  100% {
    transform: rotate(360deg);
    color: red;
  }
}

.rotating-square {
  position: relative;
  animation: rotate 2s linear infinite;
}
