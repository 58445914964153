#script-description-panel-container {
  position: fixed;
  bottom: 0;
  z-index: 200000000000;

  left: 0;
  width: 100%;
  overflow: scroll;
  padding-bottom: 12px;
}
#script-description-panel-container.hidden {
  padding-bottom: 0;
}
#script-description-panel-container > *:first-child {
  background: rgba(255, 255, 255, 0.2);
}
#script-description-panel-container.hidden > *:last-child {
  height: 0;
  overflow: hidden;
}

.head-items-full-height > *.ant-card-head > *.ant-card-head-wrapper {
  height: 100% !important;
}
.ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:last-child {
  border-radius: 0 !important;
}
.ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
  border-radius: 0 !important;
}
