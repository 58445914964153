.cs-test-menu {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.cs-test-menu__header {
  padding: 16px;
  padding-top: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background: transparent;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
}

/* Back button overrides */
.cs-test-menu__back.ant-btn {
  padding: 0 !important;
  height: auto !important;
  border: none !important;
  background: transparent !important;
  box-shadow: none !important;
  color: #65d2d8 !important;
}

.cs-test-menu__back.ant-btn:hover {
  opacity: 0.8;
}

.cs-test-menu__back.ant-btn > span {
  color: #65d2d8 !important;
  font-size: 16px;
}

.cs-test-menu__back .anticon {
  color: #65d2d8 !important;
}

.cs-test-menu__content {
  flex: 1;
  padding: 16px;
  background: transparent;
  overflow-y: auto;
}

/* Scrollbar styling */
.cs-test-menu__content::-webkit-scrollbar {
  width: 8px;
}

.cs-test-menu__content::-webkit-scrollbar-track {
  border-radius: 4px;
}

.cs-test-menu__content::-webkit-scrollbar-thumb {
  border-radius: 4px;
}

/* Grid layout for test cards */
.cs-test-menu__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 16px;
  margin-top: 16px;
}

/* Empty state */
.cs-test-menu__empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 16px;
  text-align: center;
}

.cs-test-menu__empty-icon {
  font-size: 48px;
  margin-bottom: 16px;
  opacity: 0.5;
}

.cs-test-menu__empty-text {
  font-size: 16px;
  margin-bottom: 8px;
}

.cs-test-menu__empty-subtext {
  font-size: 14px;
  opacity: 0.7;
}

.cs-test-menu__admin.ant-btn {
  color: #65d2d8 !important;
  font-size: 20px;
  padding: 0 !important;
  height: auto !important;
  border: none !important;
  background: transparent !important;
  box-shadow: none !important;
}

.cs-test-menu__admin.ant-btn:hover {
  opacity: 0.8;
}
