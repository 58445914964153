.cs-status-center {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.cs-status-center__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.cs-status-center__title {
  font-size: 15px !important;
  letter-spacing: 0.2px;
}

.cs-status-center__test-name {
  font-size: 14px !important;
  line-height: 1.3 !important;
}

.cs-status-center__time {
  font-size: 12px !important;
  line-height: 1.2 !important;
}

.cs-status-center__details {
  display: flex;
  align-items: center;
  gap: 8px;
}

.cs-status-center__view-all {
  font-size: 13px !important;
  padding: 0 !important;
  height: auto !important;
  display: flex !important;
  align-items: center;
  gap: 4px;
}

.cs-status-center__tests {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cs-status-center__test-item {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid rgba(101, 210, 216, 0.3);
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 10px rgba(101, 210, 216, 0.1);
}

/* Status-specific borders */
.cs-status-center__test-item--processing {
  border-left: 3px solid #65d2d8;
  background: linear-gradient(180deg, rgba(101, 210, 216, 0.05) 0%, rgba(255, 255, 255, 0.02) 100%);
  border-color: rgba(101, 210, 216, 0.2);
}

.cs-status-center__test-item--overdue {
  border-left: 3px solid #ef8d9c;
}

.cs-status-center__test-item--pending {
  border-left: 3px solid #fdb462;
}

.cs-status-center__main-content {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
}

.cs-status-center__test-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.cs-status-center__icon {
  font-size: 18px;
  width: 18px;
  text-align: center;
}

.cs-status-center__icon--processing {
  color: #65d2d8;
  animation: processingIcon 4s linear infinite;
}

.cs-status-center__icon--pending {
  color: #fdb462;
}

.cs-status-center__icon--overdue {
  color: #ef8d9c;
}

.cs-status-center__icon--completed {
  color: #b5d777 !important;
  animation: none !important;
  font-size: 18px;
  width: 18px;
  text-align: center;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Learn More Section */
.cs-status-center__learn-more {
  font-size: 10px !important;
  padding: 0 !important;
  height: auto !important;
  display: flex !important;
  align-items: center;
  gap: 3px;
  margin: 0 !important;
  color: #10b2e1 !important;
}

.cs-status-center__more-text {
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #10b2e1 !important;
  font-weight: 500;
}

.cs-status-center__more-text span[style*="color: #65D2D8"] {
  color: #10b2e1 !important;
}

.cs-status-center__more-text span[style*="color: #B5D777"] {
  color: #10b2e1 !important;
}

.cs-status-center__more-text span[style*="color: #FDB462"] {
  color: #10b2e1 !important;
}

.cs-status-center__more-text span[style*="color: #EF8D9C"] {
  color: #10b2e1 !important;
}

.cs-status-center__learn-more .fa-chevron-right,
.cs-status-center__learn-more .fa-chevron-down {
  font-size: 12px;
  transition: transform 0.3s ease;
  color: #10b2e1 !important;
}

.cs-status-center__learn-more:hover {
  opacity: 0.8;
}

/* New styles for info section */
.cs-status-center__info {
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-left: 0;
}

.cs-status-center__info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px 16px;
}

.cs-status-center__info-item {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.cs-status-center__info-label {
  font-size: 11px !important;
  line-height: 1.2 !important;
}

.cs-status-center__info-value {
  font-size: 12px !important;
  line-height: 1.3 !important;
}

.cs-status-center__divider {
  height: 1px;
  margin: 12px -16px 8px;
}

.cs-status-center__time-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Progress bar for processing items */
.cs-status-center__test-item--processing::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(
    90deg,
    #65d2d8 0%,
    #b5d777 25%,
    #fdb462 50%,
    #ef8d9c 75%,
    #65d2d8 100%
  );
  background-size: 400% 100%;
  animation: none;
  box-shadow: 
    0 0 10px rgba(101, 210, 216, 0.4),
    0 0 20px rgba(101, 210, 216, 0.2);
  width: 0;
  filter: brightness(1.2);
  transform-origin: left;
}

/* Add a new class for active progress */
.cs-status-center__test-item--progressing::before {
  animation: 
    progressWidth 10s linear forwards,
    progressGradient 10s linear infinite,
    glowPulse 2s ease-in-out infinite;
}

@keyframes progressWidth {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes progressGradient {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 0;
  }
}

@keyframes glowPulse {
  0% {
    filter: brightness(1.2) blur(0px);
    box-shadow: 
      0 0 10px rgba(101, 210, 216, 0.4),
      0 0 20px rgba(101, 210, 216, 0.2);
  }
  50% {
    filter: brightness(1.4) blur(0.5px);
    box-shadow: 
      0 0 15px rgba(101, 210, 216, 0.6),
      0 0 30px rgba(101, 210, 216, 0.3);
  }
  100% {
    filter: brightness(1.2) blur(0px);
    box-shadow: 
      0 0 10px rgba(101, 210, 216, 0.4),
      0 0 20px rgba(101, 210, 216, 0.2);
  }
}

/* Enhanced completion glow effect */
.cs-status-center__test-item--completed {
  animation: cardGlow 5s ease-in-out;
  border-color: rgba(101, 210, 216, 0.7) !important;
  position: relative;
  z-index: 1;
}

.cs-status-center__test-item--completed::after {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 8px;
  animation: borderGlow 5s ease-in-out;
  pointer-events: none;
  z-index: -1;
}

@keyframes cardGlow {
  0% {
    background: rgba(255, 255, 255, 0.02);
  }
  50% {
    background: rgba(101, 210, 216, 0.15);
    box-shadow: 0 0 30px rgba(101, 210, 216, 0.3), inset 0 0 30px rgba(181, 215, 119, 0.2);
  }
  100% {
    background: rgba(255, 255, 255, 0.02);
  }
}

@keyframes borderGlow {
  0% {
    box-shadow: 0 0 10px rgba(101, 210, 216, 0.4), 0 0 20px rgba(101, 210, 216, 0.3),
      inset 0 0 10px rgba(101, 210, 216, 0.2);
  }
  50% {
    box-shadow: 0 0 25px rgba(101, 210, 216, 0.7), 0 0 50px rgba(181, 215, 119, 0.5),
      inset 0 0 25px rgba(101, 210, 216, 0.3);
    transform: scale(1.01);
  }
  100% {
    box-shadow: 0 0 10px rgba(101, 210, 216, 0.4), 0 0 20px rgba(101, 210, 216, 0.3),
      inset 0 0 10px rgba(101, 210, 216, 0.2);
    transform: scale(1);
  }
}

/* Add subtle hover effect */
.cs-status-center__test-item:hover {
  transform: translateY(-1px);
  transition: all 0.3s ease;
}

@keyframes processingIcon {
  0% {
    transform: rotate(0deg);
    color: #65d2d8;
  }
  50% {
    transform: rotate(180deg);
    color: #b5d777;
  }
  100% {
    transform: rotate(360deg);
    color: #65d2d8;
  }
}

.cs-status-center__times {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.cs-status-center__time--completed {
  color: #65d2d8 !important;
  font-size: 11px !important;
}

.cs-status-center__time--remaining {
  color: #10b2e1 !important;
  font-size: 11px !important;
  opacity: 1;
  font-weight: 500;
}

/* Update the collapse button styles to match */
.cs-status-center__collapse-btn {
  font-size: 10px !important;
  padding: 0 !important;
  height: auto !important;
  display: flex !important;
  align-items: center;
  gap: 3px;
  margin: 0 !important;
}

.cs-status-center__collapse-btn .fa-chevron-right,
.cs-status-center__collapse-btn .fa-chevron-down {
  font-size: 12px;
  transition: transform 0.3s ease;
}

.cs-status-center__collapse-btn:hover {
  opacity: 0.8;
}

/* Remove the previous collapse button styles */
.cs-status-center__collapse-btn {
  background: transparent !important;
  width: auto !important;
  min-width: auto !important;
  border-radius: 0 !important;
}

/* Update header styles */
.cs-status-center__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.cs-status-center__description {
  font-size: 12px !important;
  color: rgba(255, 255, 255, 0.85) !important;
  margin: 2px 0 12px 0 !important;
  padding: 0 4px;
  line-height: 1.5;
  font-weight: 400;
}
