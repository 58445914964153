/* Container */
.medical-history-container {
  padding: 0;
  background: transparent;
  height: calc(100vh - 65px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  position: relative;
}

/* Sections Grid */
.sections-grid {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;
  padding: 0 16px 120px;
  margin: 0;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

/* Override Ant Design Card styles */

/* Section Icon */
.section-icon {
  width: 44px;
  height: 44px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  background: rgba(var(--section-color), 0.15);
  color: rgb(var(--section-color));
  flex-shrink: 0;
  transition: all 0.2s ease;
}

.section-card:hover .section-icon {
  transform: scale(1.05);
  background: rgba(var(--section-color), 0.2);
}

/* Section Info */
.section-info {
  flex: 1;
  min-width: 0;
  padding-right: 24px;
  text-align: left;
}

.section-info h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 6px;
  letter-spacing: -0.3px;
  text-align: left;
}

.section-info p {
  font-size: 12px;
  margin: 0;
  line-height: 1.3;
  text-align: left;
  padding-left: 0;
}

/* Status Icon */
.section-status {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

/* Arrow indicator */
.section-card::after {
  content: '→';
  position: absolute;
  right: 20px;
  bottom: 20px;
  font-size: 18px;
  color: rgba(var(--section-color), 0.5);
  transition: all 0.3s ease;
}

.section-card:hover::after {
  transform: translateX(4px);
  color: rgba(var(--section-color), 0.8);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.sections-grid {
  animation: fadeIn 0.3s ease-out;
}

/* Custom Scrollbar */
.sections-grid::-webkit-scrollbar {
  width: 6px;
}

.sections-grid::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 3px;
}

.sections-grid::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
}

.sections-grid::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.2);
}

/* Add padding to bottom of scrollable area */
.sections-grid::after {
  content: '';
  display: block;
  height: 80px;
}

/* Modern Progress Bar */
.progress-wrapper {
  padding: 0 16px 12px;
  margin-bottom: 4px;
  background: transparent;
}

.progress-stats {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.progress-label {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.progress-icon {
  color: #10b2e1;
  font-size: 14px;
}

.progress-percentage {
  color: #10b2e1;
  font-size: 14px;
  font-weight: 600;
  padding: 4px 10px;
  background: rgba(16, 178, 225, 0.1);
  border-radius: 12px;
}

.progress-bar {
  height: 4px;
  background: rgba(204, 224, 230, 0.1);
  border-radius: 2px;
  overflow: hidden;
}

.progress-bar-fill {
  height: 100%;
  background: linear-gradient(90deg, #10b2e1, #0ea5e9);
  border-radius: 2px;
  transition: width 0.3s ease;
}

/* Update sections grid to account for fixed progress bar */
.sections-grid {
  padding-top: 8px;
  /* rest of existing styles */
}

/* Remove all these sections */
.progress-wrapper,
.progress-stats,
.progress-label,
.progress-icon,
.progress-percentage,
.progress-bar,
.progress-bar-fill {
  display: none;
}

/* Update sections grid */
.sections-grid {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;
  padding: 0 16px 120px;
  margin: 0;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}
