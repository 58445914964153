.cs-stats-card {
  background: rgba(255, 255, 255, 0.95) !important;
  backdrop-filter: blur(10px);
  border: none !important;
  border-radius: 12px !important;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  width: 100%;
  min-width: 0;
  margin: 8px 0;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
}

/* Override Ant Design card body padding */
.cs-stats-card .ant-card-body {
  padding: 20px !important;
}

/* Background glow */
.cs-stats-card::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 14px;
  background: linear-gradient(
    145deg,
    rgba(101, 210, 216, 0.2) 0%,
    rgba(181, 215, 119, 0.1) 50%,
    rgba(101, 210, 216, 0.05) 100%
  );
  z-index: 0;
  filter: blur(2px);
}

/* Glass shine effect */
.cs-stats-card::after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 13px;
  box-shadow: 
    0 4px 24px rgba(101, 210, 216, 0.15),
    0 1px 3px rgba(101, 210, 216, 0.1),
    inset 0 2px 2px rgba(255, 255, 255, 0.3);
  z-index: 1;
}

/* Ensure content stays above glass effects */
.cs-stats-card > * {
  position: relative;
  z-index: 2;
}

.cs-stats-card__label {
  font-size: 14px;
  margin-bottom: 4px;
  color: rgba(28, 37, 54, 0.65);
  font-weight: 500;
}

.cs-stats-card__current {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 4px 0;
}

.cs-stats-card__value-display {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 4px 0;
}

.cs-stats-card__value {
  font-size: 40px;
  font-weight: 600;
  line-height: 1.2;
  color: #1c2536;
  background: linear-gradient(135deg, #1c2536 0%, #2c3b52 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cs-stats-card__previous {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 4px 0;
}

.cs-stats-card__previous-label {
  font-size: 13px;
  color: rgba(28, 37, 54, 0.5);
  font-weight: 500;
}

.cs-stats-card__previous-value {
  display: flex;
  align-items: baseline;
  gap: 6px;
  font-size: 14px;
  color: rgba(28, 37, 54, 0.85);
}

.cs-stats-card__previous-value .cs-stats-card__unit {
  font-size: 12px;
  margin-right: 0;
  color: rgba(28, 37, 54, 0.5);
}

.cs-stats-card__unit {
  font-size: 18px;
  margin-right: 8px;
  color: rgba(28, 37, 54, 0.65);
  font-weight: 500;
}

.cs-stats-card__change {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  padding: 6px 12px;
  border-radius: 8px;
  font-weight: 500;
}

.cs-stats-card__change--improving {
  color: #389e0d;
  background: rgba(82, 196, 26, 0.15);
  border: 1px solid rgba(82, 196, 26, 0.2);
}

.cs-stats-card__change--worsening {
  color: #cf1322;
  background: rgba(255, 77, 79, 0.15);
  border: 1px solid rgba(255, 77, 79, 0.2);
}

/* Status tag styling */
.cs-stats-card__status-tag {
  border-radius: 8px;
  padding: 6px 16px;
  font-size: 13px;
  font-weight: 500;
  margin-top: 4px;
  background: rgba(101, 210, 216, 0.15);
  color: #10b2e1;
  border: 1px solid rgba(101, 210, 216, 0.3);
}

/* Add hover effect */
.cs-stats-card:hover {
  transform: translateY(-2px);
  box-shadow: 
    0 8px 32px rgba(101, 210, 216, 0.15),
    0 2px 8px rgba(101, 210, 216, 0.1);
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .cs-stats-card .ant-card-body {
    padding: 16px !important;
  }
  
  .cs-stats-card__value {
    font-size: 36px;
  }
  
  .cs-stats-card__unit {
    font-size: 16px;
  }
}

.cs-stats-card__expand {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cs-stats-card__expand-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
}

.cs-stats-card__expand-icon {
  font-size: 12px;
  transition: transform 0.3s ease;
}

.cs-stats-card__expand-icon.expanded {
  transform: rotate(180deg);
}

.cs-stats-card__breakdown {
  width: 100%;
  margin-top: 16px;
}

/* Override BloodCountBreakdown styles when inside StatsCard */
.cs-stats-card__breakdown .cs-breakdown {
  background: transparent !important;
  backdrop-filter: none;
}

.cs-stats-card__breakdown .cs-breakdown__title {
  font-size: 16px;
  margin-bottom: 16px !important;
}

.cs-stats-card__breakdown .cs-breakdown__list {
  gap: 20px;
}

.cs-stats-card__breakdown .cs-breakdown__item {
  gap: 8px;
}

/* Make the header and progress bar use more width */
.cs-stats-card__breakdown .cs-breakdown__header {
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
}

.cs-stats-card__breakdown .cs-breakdown__progress {
  margin: 4px 0;
}

.cs-stats-card__breakdown .cs-breakdown__range {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 4px;
}

/* Progress bar styling */
.cs-stats-card__breakdown .ant-progress-bg {
  height: 4px !important;
}

.cs-stats-card__breakdown .cs-breakdown__header .ant-typography {
  font-size: 14px;
}

.cs-stats-card__divider {
  height: 1px;
  margin: 0 -16px 16px;
}
