.video-consultation {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 60px;

  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.video-grid {
  flex: 1;
  display: grid;
  grid-template-rows: 1fr 150px;
  gap: 8px;
  padding: 16px;
  padding-bottom: 100px;
}

.remote-video {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  position: relative;
  overflow: hidden;
}

.doctor-name {
  position: absolute;
  bottom: 16px;
  left: 16px;
  color: white;
  font-size: 14px;
  background: rgba(0, 0, 0, 0.5);
  padding: 4px 12px;
  border-radius: 20px;
}

.local-video {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  align-self: end;
}

.video-controls {
  position: fixed;
  bottom: 68px;
  left: 0;
  right: 0;
  padding: 16px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  z-index: 1001;
}

.duration {
  text-align: center;
  color: white;
  font-size: 14px;
  margin-bottom: 16px;
}

.control-buttons {
  display: flex;
  justify-content: center;
  gap: 24px;
  padding-bottom: 8px;
}

.control-button {
  width: 48px !important;
  height: 48px !important;
  border-radius: 24px !important;
  background: rgba(255, 255, 255, 0.1) !important;
  border: none !important;
  color: white !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.control-button.off {
  background: rgba(255, 59, 48, 0.2) !important;
  color: #ff3b30 !important;
}

.end-call {
  width: 48px !important;
  height: 48px !important;
  border-radius: 24px !important;
  background: #ff3b30 !important;
  border: none !important;
  color: white !important;
}
