/* Override Ant Design drawer styles */

.cs-filter-drawer .ant-drawer-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
  padding: 16px 24px !important;
}

.cs-filter-drawer .ant-drawer-title {
  font-size: 18px !important;
  font-weight: 500 !important;
}

.cs-filter-drawer .ant-drawer-body {
  padding: 24px !important;
  background: #141824 !important;
}

.cs-filter-drawer__section {
  margin-bottom: 32px;
}

.cs-filter-drawer__section h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}

/* Remove these styles */
.cs-filter-drawer__info,
.cs-filter-drawer__caresync-info,
.cs-filter-drawer__caresync-info .ant-typography,
.cs-filter-drawer__caresync-info strong,
.cs-filter-drawer__caresync-info ul,
.cs-filter-drawer__caresync-info li {
  display: none;
}

.cs-filter-drawer .ant-radio-checked .ant-radio-inner,
.cs-filter-drawer .ant-checkbox-checked .ant-checkbox-inner {
  border-color: #65d2d8 !important;
}

.cs-filter-drawer .ant-radio-checked .ant-radio-inner::after {
  background-color: #65d2d8 !important;
}

.cs-filter-drawer .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #65d2d8 !important;
}

/* Add footer background */
.cs-filter-drawer .ant-drawer-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
  padding: 16px 24px !important;
}

/* Footer styling */
.cs-filter-drawer__footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 16px 24px;
}

/* Remove the old button styles since we're using MobTransparentButton */
.cs-filter-drawer__btn,
.cs-filter-drawer__btn--reset,
.cs-filter-drawer__btn--apply {
  display: none;
}
