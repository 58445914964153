.report-print-button {
  transition: 450ms ease;
  background: #ccc;
  cursor: pointer;
}
.report-print-button:hover {
  transition: background 450ms ease;
  background: #52c41a;
  color: #fff;
}
