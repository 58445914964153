.cs-overview__results-card {
  border-radius: 8px;
  backdrop-filter: blur(10px);
  border: none !important;
}

.cs-overview__results-card .ant-typography {
  margin: 0 !important;
}

.cs-overview__result-item {
  padding: 16px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cs-overview__result-item:last-child {
  border-bottom: none;
}

.cs-overview__result-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cs-overview__reading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cs-overview__reading-info {
  display: flex;
  flex-direction: column;
}

.cs-overview__reading-value {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
}

.cs-overview__reading-value--improving,
.cs-overview__reading-value--improving .ant-typography {
  color: #52c41a !important;
}

.cs-overview__reading-value--worsening,
.cs-overview__reading-value--worsening .ant-typography {
  color: #ff4d4f !important;
}

.cs-overview__reading-date {
  font-size: 14px;
}

.cs-overview__result-item .ant-btn-link {
  color: #4cc9f0 !important;
}

.cs-results-center {
  border-radius: 12px;
  padding: 16px;
  backdrop-filter: blur(10px);
}

.cs-results-center__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
}

.cs-results-center__title {
  font-size: 16px;
  font-weight: 500;
  margin: 0 !important;
}

.cs-results-center__description {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.65);
  margin: 0 0 12px 0;
  padding: 0 4px;
}

.cs-results-center__tests {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cs-results-center__test-item {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(101, 210, 216, 0.6);
  border-radius: 8px;
  padding: 12px;
  transition: all 0.3s ease;
  box-shadow: 0 0 10px rgba(101, 210, 216, 0.15);
}

.cs-results-center__test-item:hover {
  background: rgba(255, 255, 255, 0.08);
  border-color: rgba(101, 210, 216, 0.8);
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(101, 210, 216, 0.2);
}

.cs-results-center__main-content {
  display: flex;
  gap: 12px;
}

.cs-results-center__test-info {
  flex: 1;
  min-width: 0;
}

.cs-results-center__test-name {
  font-size: 14px !important;
  margin: 0 !important;
}

.cs-results-center__time-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-top: 4px;
}

.cs-results-center__times {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.cs-results-center__time {
  font-size: 12px !important;
}

.cs-results-center__reading {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 2px;
}

.cs-results-center__value {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}

.cs-results-center__unit {
  font-size: 12px;
  opacity: 0.7;
  margin-left: 2px;
}

.cs-results-center__collapse-btn {
  padding: 4px 8px !important;
  height: 32px !important;
}

/* Action buttons */
.cs-result-item__actions {
  display: flex;
  align-items: center;
  gap: 4px;
}

.cs-result-item__action-btn {
  color: rgba(255, 255, 255, 0.45) !important;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cs-result-item__detail-btn {
  color: #65d2d8 !important;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cs-results-center__status-tag {
  font-size: 11px;
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  height: 20px;
  white-space: nowrap;
}

.cs-results-center__status-tag--low {
  background: rgba(239, 141, 156, 0.15);
  color: #ef8d9c;
  border: 1px solid rgba(239, 141, 156, 0.3);
}

.cs-results-center__status-tag--normal {
  background: rgba(181, 215, 119, 0.15);
  color: #b5d777;
  border: 1px solid rgba(181, 215, 119, 0.3);
}

.cs-results-center__status-tag--high {
  background: rgba(253, 180, 98, 0.15);
  color: #fdb462;
  border: 1px solid rgba(253, 180, 98, 0.3);
}
