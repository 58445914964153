.tasks-header {
  margin-bottom: 24px;
}

.tasks-header .progress-card {
  background: #141824 !important;
  border: 1px solid rgba(16, 178, 225, 0.25) !important;
  border-radius: 12px !important;
  padding: 20px !important;
}

/* Progress Section */
.tasks-header .progress-section {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(16, 178, 225, 0.25);
}

.tasks-header .progress-title {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 16px;
}

.tasks-header .progress-indicator {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.tasks-header .progress-circles {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 0 4px;
}

.tasks-header .progress-circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgba(16, 178, 225, 0.05);
  border: 2px solid rgba(16, 178, 225, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.tasks-header .progress-circle svg {
  font-size: 12px;
  color: rgba(16, 178, 225, 0.3);
  transition: all 0.3s ease;
}

.tasks-header .progress-circle.completed {
  background: rgba(16, 178, 225, 0.1);
  border-color: #10b2e1;
}

.tasks-header .progress-circle.completed svg {
  color: #10b2e1;
  filter: drop-shadow(0 0 6px rgba(16, 178, 225, 0.5));
}

.tasks-header .progress-bar {
  height: 6px;
  background: rgba(16, 178, 225, 0.1);
  border-radius: 3px;
  overflow: hidden;
}

.tasks-header .progress-fill {
  height: 100%;
  background: linear-gradient(90deg, #10b2e1, #e91e63);
  border-radius: 3px;
  transition: width 0.3s ease;
}

.tasks-header .progress-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.tasks-header .progress-percentage {
  font-size: 24px;
  font-weight: 700;
  background: linear-gradient(135deg, #10b2e1, #e91e63);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 10px rgba(16, 178, 225, 0.3);
}

.tasks-header .progress-count {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
}

/* Stats Grid */
.tasks-header .stats-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.tasks-header .stat-box {
  background: rgba(16, 178, 225, 0.05);
  border: 1.5px solid rgba(16, 178, 225, 0.25);
  border-radius: 12px;
  padding: 16px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.tasks-header .stat-box:hover {
  transform: translateY(-2px);
  background: rgba(16, 178, 225, 0.1);
  border-color: rgba(16, 178, 225, 0.4);
  box-shadow: 0 4px 15px rgba(16, 178, 225, 0.25);
}

.tasks-header .stat-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;
}

.tasks-header .stat-icon {
  font-size: 24px;
  transition: all 0.3s ease;
  margin-bottom: 4px;
}

.tasks-header .stat-icon.points {
  color: #e91e63;
  filter: drop-shadow(0 0 8px rgba(233, 30, 99, 0.3));
}

.tasks-header .stat-icon.streak {
  color: #ffd700;
  filter: drop-shadow(0 0 8px rgba(255, 215, 0, 0.3));
}

.tasks-header .stat-value {
  font-size: 28px;
  font-weight: 700;
  color: #fff;
  line-height: 1;
}

.tasks-header .stat-label {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

/* Media Queries */
@media (max-width: 480px) {
  .tasks-header .progress-card {
    padding: 16px !important;
  }

  .tasks-header .progress-circle {
    width: 20px;
    height: 20px;
  }

  .tasks-header .progress-circle svg {
    font-size: 10px;
  }

  .tasks-header .progress-percentage {
    font-size: 20px;
  }

  .tasks-header .progress-count {
    font-size: 12px;
  }

  .tasks-header .stat-box {
    padding: 12px;
  }

  .tasks-header .stat-icon {
    font-size: 20px;
  }

  .tasks-header .stat-value {
    font-size: 24px;
  }
} 