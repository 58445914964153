/* .rbc-event {
   width: 100% !important;
} */
.rbc-event:hover {
  border: 3px rgba(0, 0, 0, 1) solid;
}

.rbc-label {
  font-size: 10px;
}

.rbc-time-slot {
  min-height: 30px;
}
.rbc-time-slot:hover {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.255);
}

.rbc-event-label {
  display: none;
}
.empty-slot {
  cursor: not-allowed !important; /* Change cursor to pointer when hovering over empty slots */
}

/* Media query for medium screens */
@media screen and (max-width: 1000px) {
  .responsive-modal {
    width: 100% !important; /* Adjust the width for medium screens */
  }
}

/* Media query for extra large screens */
@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .responsive-modal {
    width: 80% !important; /* Adjust the width for extra large screens */
  }
}

/* Media query for extra large screens */
@media screen and (min-width: 1200px) {
  .responsive-modal {
    width: 50% !important; /* Adjust the width for extra large screens */
  }
}
