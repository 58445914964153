.admin-add-clinic {
  width: 100%;
  min-height: 100%;
  padding: 20px;
  text-align: center;
  align-items: center;
  transition: 450ms ease;
  cursor: pointer;
}
.admin-add-clinic:hover {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
}

.admin-add-clinic > * > * > * {
  transition: 150ms ease;
}
.admin-add-clinic:hover > * > * > * {
  font-size: 100px !important;
}
