.mobile-booking-details {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  padding: 16px;
  padding-top: 96px;
  padding-bottom: 100px;
}

.booking-header {
  text-align: center;
}

.booking-header h2 {
  font-size: 24px;
  font-weight: 600;
  padding: 12px 16px;
  background: rgba(0, 230, 255, 0.1);
  border: 2px solid rgba(0, 230, 255, 0.2);
  border-radius: 12px;
  display: inline-block;
}

.booking-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 134px;
}

.form-group {
  position: relative;
}

.input-icon {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(0, 230, 255, 0.8);
  font-size: 18px;
  z-index: 1;
}

.date-input,
.select-input {
  width: 100% !important;
  height: 56px !important;
  background: rgba(0, 230, 255, 0.05) !important;
  border: 2px solid rgba(0, 230, 255, 0.2) !important;
  border-radius: 12px !important;
  padding-left: 48px !important;
}

.date-input:hover,
.select-input:hover {
  border-color: rgba(0, 230, 255, 0.4) !important;
}

.date-input:focus,
.select-input:focus {
  border-color: rgba(0, 230, 255, 0.6) !important;
  box-shadow: 0 0 0 2px rgba(0, 230, 255, 0.1) !important;
}

.next-button {
  position: fixed !important;
  bottom: 70px !important;
  left: 16px !important;
  right: 16px !important;
  height: 48px !important;
  background: #10b2e1 !important;
  border: none !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  z-index: 99 !important;
}

/* Patient Selection Styles */
.patient-selection {
  margin: 16px 0;
}

.patient-selection h3 {
  font-size: 16px;
  margin-bottom: 12px;
  font-weight: 500;
}

.patient-options {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  padding: 0 4px;
}

.patient-option {
  height: 64px !important;
  width: 100% !important;
  min-width: 0 !important;
  max-width: none !important;
  background: rgba(16, 178, 225, 0.05) !important;
  border: 1px solid rgba(16, 178, 225, 0.2) !important;
  border-radius: 12px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 4px !important;
  transition: all 0.2s ease !important;
  padding: 8px 8px !important;
  flex-shrink: 0 !important;
  flex-grow: 0 !important;
}

.patient-option span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px !important;
  line-height: 1 !important;
}

.patient-option.selected {
  background: rgba(16, 178, 225, 0.1) !important;
  border-color: #10b2e1 !important;
  color: #10b2e1 !important;
}

.patient-option:hover {
  border-color: #10b2e1 !important;
}

.patient-option .anticon {
  font-size: 14px !important;
  flex-shrink: 0 !important;
  margin-right: 2px !important;
}
