.health-tracking-tab .metric-sections {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
}

.health-tracking-tab .metric-section {
  width: 100%;
}

.health-tracking-tab .metric-section .section-title {
  color: rgba(255, 255, 255, 0.9);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 8px;
  border-left: 2px solid rgba(16, 178, 225, 0.4);
}

.health-tracking-tab .metrics-grid {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

/* Card specific overrides */
.health-tracking-tab .metric-card.ant-card {
  width: 100%;
  margin-bottom: 0;
}

/* Ensure proper container behavior */
.health-tracking-tab {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 100px; /* Space for bottom navigation/content */
}

/* Scrollbar styling */
.health-tracking-tab::-webkit-scrollbar {
  width: 8px;
}

.health-tracking-tab::-webkit-scrollbar-track {
  background: rgba(16, 178, 225, 0.05);
  border-radius: 4px;
}

.health-tracking-tab::-webkit-scrollbar-thumb {
  background: rgba(16, 178, 225, 0.2);
  border-radius: 4px;
}

.health-tracking-tab::-webkit-scrollbar-thumb:hover {
  background: rgba(16, 178, 225, 0.3);
} 