.mobile-quick-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  padding: 16px;
  max-width: 380px;
  margin: 0 auto;
  width: 100%;
}

.quick-button-card {
  background: #ffffff;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  transition: transform 0.2s ease;
  position: relative;
  justify-content: center;
  border: 1px solid rgba(16, 178, 225, 0.15);
}

.quick-button-card .icon-container {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 20;
  justify-content: center;
}

.quick-button-card .icon-container svg {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.9);
}

.quick-button-card .button-title {
  color: '#000000';
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  margin: 0;
}

/* Background glow */
.quick-button-card::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 16px;
  background: linear-gradient(145deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0.02) 100%);
  z-index: 0;
  filter: blur(2px);
}

/* Button face shine */
.quick-button-card::after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2), 0 0 15px rgba(0, 0, 0, 0.05), inset 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.quick-button-card > * {
  position: relative;
  z-index: 2;
}

.quick-button-card:hover {
  transform: translateY(-2px);
}

.quick-button-card:active {
  transform: translateY(0);
}
