.cs-search {
  padding: 16px;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.cs-search__input-wrapper {
  display: flex;
  gap: 12px;
}

.cs-search .ant-input-affix-wrapper {
  background: transparent !important;
  border: none !important;
  padding: 0 12px !important;
  box-shadow: none !important;
  height: 46px !important;
}

.cs-search .ant-input {
  font-size: 16px !important;
  padding: 0 !important;
}

.cs-search .ant-input-prefix {
  margin-right: 8px;
  font-size: 16px;
}

/* Move search icon to right */
.cs-search .ant-input-suffix {
  margin-left: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.cs-search__icon {
  font-size: 16px;
}

/* Update clear button position */
.cs-search .ant-input-clear-icon {
  font-size: 14px;
  margin-right: 4px;
}

/* Filter Button */
.cs-search__filter-btn {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background: transparent;
  border: 1.5px solid rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  position: relative;
  transition: all 0.2s ease;
}

.cs-search__filter-btn:hover {
  border-color: rgba(101, 210, 216, 0.6);
  color: #65d2d8;
}

.cs-search__filter-btn.active {
  background: rgba(101, 210, 216, 0.1);
  border-color: #65d2d8;
  color: #65d2d8;
}

.cs-search__filter-count {
  position: absolute;
  top: -6px;
  right: -6px;
  background: #65d2d8;
  color: #141824;
  border-radius: 10px;
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  padding: 0 6px;
}

/* Search Options */
.cs-search__option {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.cs-search__option-name {
  font-weight: 500;
  flex: 1;
  font-size: 15px;
}

.cs-search__option-category {
  font-size: 12px;
  padding: 2px 8px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
}

/* Ant Design Tag overrides */
.cs-search__chip.ant-tag {
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-radius: 20px !important;
  padding: 6px 12px !important;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 0;
}

.cs-search__chip--active.ant-tag {
  background: rgba(101, 210, 216, 0.2) !important;
  border-color: #65d2d8 !important;
  color: #65d2d8 !important;
}

.cs-search__chip--active:hover {
  background: rgba(101, 210, 216, 0.3) !important;
}

.cs-search__chip-icon {
  font-size: 12px;
}

/* Space component override */
.cs-search .ant-space {
  flex-wrap: wrap;
  gap: 8px !important;
}

/* Add to existing styles */

.cs-search__autocomplete {
  flex: 1;
}

/* Update spinner position */
.cs-search__spinner {
  margin: 0 !important;
}

.cs-search__spinner .ant-spin-dot-item {
  background-color: rgba(255, 255, 255, 0.45) !important;
}

/* Keyboard Navigation */
.cs-search__option-keys {
  display: flex;
  gap: 4px;
  font-size: 10px;
  padding: 2px 6px;
  border-radius: 4px;
}

.cs-search__option-keys svg {
  width: 8px;
  height: 8px;
}

/* Animations */
@keyframes slideDown {
  from {
    opacity: 1;
    transform: none;
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeIn {
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}

.cs-search-dropdown {
  animation: slideDown 0.2s ease-out;
}

.cs-search__option {
  animation: fadeIn 0.15s ease-out;
}

.cs-search__filter-count {
  animation: fadeIn 0.2s ease-out;
}

/* Hover Effects */
.cs-search__filter-btn {
  transition: all 0.2s ease;
}

.cs-search .ant-input-clear-icon {
  transition: color 0.2s ease;
}
