.progress-tracking {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.metrics-card,
.schedule-card {
  background: rgba(16, 178, 225, 0.05);
  border: 1px solid rgba(16, 178, 225, 0.1);
  border-radius: 12px;
  padding: 16px;
}

.metrics-card h3,
.schedule-card h3 {
  color: #fff;
  font-size: 16px;
  margin-bottom: 16px;
  text-align: center;
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  margin-bottom: 8px;
}

.metric-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  color: #fff;
}

.metric-item svg {
  color: #10b2e1;
  font-size: 18px;
}

.metric-item span {
  font-size: 13px;
  text-transform: capitalize;
  line-height: 1.2;
}

.schedule-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.schedule-frequency {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-transform: capitalize;
}

.schedule-tests {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.test-item {
  background: rgba(16, 178, 225, 0.1);
  border: 1px solid rgba(16, 178, 225, 0.2);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.9);
  white-space: nowrap;
}
