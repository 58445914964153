#landing-hero {
  background: url('https://thumbs.dreamstime.com/b/hands-medical-doctor-blue-health-care-background-32542123.jpg')
    center;
  background-size: 100% auto;
}
#landing-hero > *:first-child > * {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 65vh;
}
.landing-login-box {
  transition: 2000ms ease;
  box-shadow: 0px 0px 7px 0px #eb1462 !important;
}
.landing-login-box.change {
  box-shadow: 0px 0px 7px 0px #09aedf !important;
}

.sign-register-button {
  display: flex !important;
  justify-content: left !important;
  align-items: center;
  padding: 0 !important;
  gap: 8px;
  padding: 12px !important;
  width: 350px;
  max-width: 100%;
  overflow: hidden;
  border-radius: 12px;
  border: 1px rgba(0, 0, 0, 0) solid;
  color: white;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 1.5px;
}
.sign-register-button > *:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-right: 1px solid rgba(255, 255, 255, 0.33);
}
