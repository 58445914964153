.exercise-modal .ant-modal-content {
  border-radius: 16px;
  padding: 24px;
}

.exercise-modal-content {
  color: #fff;
}

.exercise-modal-content h2 {
  font-size: 24px;
  margin-bottom: 24px;
  text-align: center;
  color: #fff;
}

.exercise-image {
  margin-bottom: 24px;
  border-radius: 12px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.2);
}

.exercise-image img {
  width: 100%;
  height: auto;
  display: block;
}

.instructions-section {
  margin-bottom: 24px;
}

.instructions-section h3 {
  color: #10b2e1;
  font-size: 18px;
  margin-bottom: 16px;
}

.instructions-section ol {
  padding-left: 20px;
  margin: 0;
}

.instructions-section li {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 12px;
  line-height: 1.5;
}

.tips-section {
  background: rgba(255, 193, 7, 0.1);
  border: 1px solid rgba(255, 193, 7, 0.2);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
}

.tip-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
}

.tips-section h3 {
  font-size: 16px;
  margin: 0;
}

.tips-section p {
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
  line-height: 1.5;
}

.muscles-section {
  margin-bottom: 24px;
}

.muscles-section h3 {
  color: #10b2e1;
  font-size: 18px;
  margin-bottom: 16px;
}

.muscle-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.muscle-tag {
  background: rgba(16, 178, 225, 0.1);
  border: 1px solid rgba(16, 178, 225, 0.2);
  color: #10b2e1;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 14px;
}

.modifications-section {
  display: grid;
  gap: 16px;
  margin-bottom: 24px;
}

.modification h4 {
  color: rgba(255, 255, 255, 0.9);
  font-size: 14px;
  margin-bottom: 8px;
}

.modification p {
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
}

.start-exercise-btn {
  width: 100%;
  height: 44px;
  background: linear-gradient(145deg, #10b2e1, #0c8ab2);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 500;
}

.start-exercise-btn:disabled {
  background: rgba(16, 178, 225, 0.1);
  color: rgba(255, 255, 255, 0.5);
}
