.cs-time-picker {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  background: transparent !important;
}

.cs-time-picker__day {
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-radius: 12px !important;
  overflow: hidden;
  position: relative;
}

/* Glass effect */
.cs-time-picker__day::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 14px;
  z-index: 0;
  filter: blur(2px);
}

.cs-time-picker__date {
  padding: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: 1;
}

.cs-time-picker__date-text {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.cs-time-picker__slots {
  padding: 16px;
  position: relative;
  z-index: 1;
}

.cs-time-picker__radio-group {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 12px;
  width: 100%;
}

/* Radio button styling */
.cs-time-picker__radio {
  margin: 0 !important;
}

.cs-time-picker__radio .ant-radio {
  top: 0;
}

.cs-time-picker__radio .ant-radio-inner {
  background-color: transparent !important;
  border-color: rgba(24, 144, 255, 0.5) !important;
}

.cs-time-picker__radio.ant-radio-wrapper-checked {
  background: rgba(24, 144, 255, 0.1);
  border-radius: 8px;
}

.cs-time-picker__radio .ant-radio-checked .ant-radio-inner {
  background-color: #1890ff !important;
  border-color: #1890ff !important;
}

.cs-time-picker__slot {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
}

.cs-time-picker__time {
  font-size: 14px;
  margin: 0;
}

/* Hover states */
.cs-time-picker__radio:hover .ant-radio-inner {
  border-color: #1890ff !important;
}

.cs-time-picker__radio:hover .cs-time-picker__slot {
  color: #1890ff;
}

.cs-time-picker__radio:hover .cs-time-picker__time {
  color: #1890ff !important;
}
