@import url('https://fonts.googleapis.com/css2?family=Inter&family=Nunito+Sans:opsz@6..12&family=Poppins:display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box !important;
}

h1,
.main-heading {
  /* font-family: 'Nunito Sans', sans-serif !important; */
  font-size: 19px;
  font-weight: bold;
}
h2,
.secondary-heading {
  /* font-family: 'Nunito Sans', sans-serif !important; */
  font-size: 17px;
  font-weight: bold;
}

h3,
h4,
h5,
h6,
.sub-heading {
  /* font-family: 'Nunito Sans', sans-serif !important; */
  font-size: 15px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 1px;
  margin: 0;
}
p,
button,
li,
a,
div,
span,
label,
input {
  letter-spacing: 1px;
  margin: 0;
  font-size: 15px;
}
label {
  letter-spacing: 1px;
  font-size: 13px !important;
  opacity: 0.75;
}

tbody:hover {
  cursor: pointer;
}

.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-3 {
  margin-bottom: 3rem;
}
.mb-4 {
  margin-bottom: 4rem;
}
.ant-layout {
  background: rgba(244, 249, 250, 0.955) !important;
}

.ant-table-row.dark {
  background: #333333 !important;
}
.ant-table-row.light {
  background: #ffffff !important;
}
.css-167rjg-control {
  box-shadow: none !important;
}
.css-167rjg-control:hover {
  box-shadow: none !important;
  border: none !important;
}
.ant-table-wrapper.dark > * > * > * > * > *::-webkit-scrollbar {
  width: auto !important;
}

.ant-table-wrapper.dark > * > * > * > * > *::-webkit-scrollbar-track {
  background-color: #1b1b1b !important;
}

.ant-table-wrapper.dark > * > * > * > * > *::-webkit-scrollbar-thumb {
  background-color: rgba(252, 0, 101, 0.5) !important;
  border: 3px solid #1b1b1b !important;
  border-radius: 5px;
}
.ant-table-wrapper.light > * > * > * > * > *::-webkit-scrollbar {
  width: auto !important;
}

.ant-table-wrapper.light > * > * > * > * > *::-webkit-scrollbar-track {
  background-color: #ffffff !important;
}

.ant-table-wrapper.light > * > * > * > * > *::-webkit-scrollbar-thumb {
  background-color: rgba(17, 173, 214, 0.35) !important;
  border-radius: 5px !important;
  border: 3px solid #ffffff !important;
}

.shortcut-icon {
  font-size: 36px !important;
  transition: 250ms ease;
  cursor: pointer;
}
.shortcut-icon:hover {
  transform: scale(1.2);
}

.background-test {
  background: url('./assets/images/landing-wallpaper.webp') center;
  background-size: auto 1680px;
}

@media (max-width: 1250px) {
  .background-test {
    background: url('./assets/images/landing-wallpaper.webp') center;
    background-size: auto 100%;
  }
}
.landing-graphic {
  min-height: 100vh;
  height: 100%;
  min-width: 100%;
  background-size: auto 100%;
  color: white;
}
.landing-graphic > *:first-child {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 5vw;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    -135deg,
    rgba(55, 87, 206, 0.7),
    rgba(37, 102, 209, 0.7),
    rgba(30, 115, 209, 0.7),
    rgba(40, 127, 207, 0.7),
    rgba(59, 138, 204, 0.7),
    rgba(86, 139, 208, 0.7),
    rgba(109, 139, 211, 0.7),
    rgba(109, 139, 211, 0.7),
    rgba(129, 139, 212, 0.7),
    rgba(174, 122, 204, 0.7),
    rgba(215, 101, 177, 0.7),
    rgba(241, 82, 133, 0.8),
    rgba(235, 20, 99, 0.8)
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6b975b1', endColorstr='#e6292765', GradientType=1 );
  height: 100vh;
  max-width: 100%;
  overflow-y: hidden;
  text-align: center;
}

.agilite_message-custom-content {
  padding: 10px 20px;
  font-size: 18px;
}

.table-row-no-cursor {
  cursor: default;
}

.ant-spin-blur::after {
  display: none !important;
}

.override-nav-margin > * {
  margin-bottom: 0 !important;
}

.ant-modal.light > *.ant-modal-content {
  background: #ffffff !important;
}

.ant-modal.light > *.ant-modal-content > * > * > * > *.ant-modal-confirm-title {
  color: #141824 !important;
}

.ant-modal.light > *.ant-modal-content > * > * > * > *.ant-modal-confirm-content {
  color: #141824 !important;
}

.ant-modal.dark > *.ant-modal-content {
  background: #141824 !important;
}

.ant-modal.dark > *.ant-modal-content > * > * > * > *.ant-modal-confirm-title {
  color: #e3e6ec !important;
}

.ant-modal.dark > *.ant-modal-content > * > * > * > *.ant-modal-confirm-content {
  color: #e3e6ec !important;
}

.ant-row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.ant-col {
  margin: 0;
}

.basic-card {
  border-radius: 12px;
  background: white;
  padding: 12px;
  align-items: flex-start;
  padding-top: 24px;
  padding-bottom: 24px;
}
@media (max-width: 600px) {
  .basic-card {
    padding: 24px 8px;
  }
}

.ant-tabs.no-margin > *.ant-tabs-nav {
  margin: 0px 0px 0px 0px !important;
}
.ant-tabs.no-border > *.ant-tabs-nav {
}

.ant-tabs.examination-screens > * > * > * > * {
  border: none !important;
}

.ant-tabs.examination-screens > *.ant-tabs-nav::before {
  border: none !important;
}
.ant-tabs.secondary-examination-screens > * > * > * > *.ant-tabs-tab:not(.ant-tabs-tab-active) {
  border-color: #10b2e1 !important;
  border-bottom: none !important;
  border-top: none;
  border-right: none;
  border-left: none;
}
.ant-tabs.secondary-examination-screens > * > * > * > *.ant-tabs-tab:not(.ant-tabs-tab-active) > *.ant-tabs-tab-btn,
/* .ant-tabs.examination-screens > * > * > * > *.ant-tabs-tab:not(.ant-tabs-tab-active) > *.ant-tabs-tab-btn {
  color: #ffffff !important;
} */
.ant-tabs.secondary-examination-screens > * > * > * > *.ant-tabs-tab-active {
  border-color: #10b2e1 !important;
  border-bottom: none !important;
}

.ant-tabs.secondary-examination-screens > *.ant-tabs-nav::before {
  border-color: #10b2e1 !important;
}
.ant-tabs.secondary-examination-screens > * > * > * > *.ant-tabs-tab-active {
  background: #f5fcfe !important;
}

.ant-table-row:hover td {
  background: #f5f5f5 !important;
}

.ant-table-filter-dropdown > *.ant-dropdown-menu {
  background-color: #ffffff !important;
}

.ant-form-item-explain-error {
  font-size: 11px;
}

.custom-loading-mask .ant-table-wrapper::after,
.custom-loading-mask .ant-spin-nested-loading::after,
.custom-loading-mask .ant-spin-container::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8) !important; /* Adjust the opacity as needed */
  z-index: 1;
}
