.glass-appointment-card {
  position: relative;
  z-index: 1;
  border-radius: 16px;
  padding: 20px;
  border: 1px solid rgba(16, 178, 225, 0.15);
  overflow: hidden;
  width: 100%;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  will-change: transform;
}

/* Glass effect - background glow */
.glass-appointment-card::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 14px;
  background: linear-gradient(
    145deg,
    rgba(16, 178, 225, 0.15) 0%,
    rgba(16, 178, 225, 0.05) 50%,
    rgba(16, 178, 225, 0.02) 100%
  );
  z-index: 0;
  filter: blur(2px);
}
