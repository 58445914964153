/* Quick Info */
.test-detail-quick-info {
  margin-bottom: 24px;
  padding: 0 8px;
}

.test-detail-info-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 16px;
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.test-detail-info-group:hover {
  background: rgba(255, 255, 255, 0.08);
  border-color: rgba(255, 255, 255, 0.15);
}

.test-detail-section-label {
  margin-bottom: 8px;
}

.test-detail-section-label .ant-typography {
  color: rgba(255, 255, 255, 0.45);
  font-size: 12px;
  letter-spacing: 0.5px;
}

.test-detail-info-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.test-detail-info-item .ant-typography,
.test-detail-info-item svg,
.test-detail-price.ant-typography {
  color: rgba(255, 255, 255, 0.85);
}

/* Update certification styles */
.test-detail-certifications {
  display: flex;
  gap: 12px;
  margin: 0;
  padding: 0;
}

.test-detail-cert {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #65D2D8;
}

.test-detail-cert svg {
  font-size: 14px;
  opacity: 0.9;
}

.test-detail-cert span {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.test-detail-specimen-tags {
  display: flex;
  gap: 4px;
}

.test-detail-specimen-tag.ant-tag {
  background: rgba(181, 215, 119, 0.1);
  border-color: #B5D777;
  color: #B5D777;
}

.info-icon {
  margin-left: 6px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.45);
  cursor: pointer;
  transition: color 0.2s;
}

.info-icon:hover {
  color: #65D2D8;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .test-detail-quick-info {
    grid-template-columns: 1fr;
  }
} 