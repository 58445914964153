.error-screen-container {
  min-height: calc(100vh - 120px);
  padding: 16px 16px 80px 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.error-screen-container .error-card {
  background: rgba(20, 24, 36, 0.95);
  border: 1px solid rgba(255, 59, 48, 0.4);
  border-radius: 12px;
  padding: 32px;
  width: 100%;
  max-width: 500px;
  text-align: center;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  margin-top: -180px;
}

.error-screen-container .error-icon {
  font-size: 48px;
  color: #ff3b30;
  margin-bottom: 24px;
}

.error-screen-container h2 {
  color: #fff;
  font-size: 24px;
  margin-bottom: 12px;
  font-weight: 600;
}

.error-screen-container .error-message {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  margin-bottom: 32px;
  padding: 0;
  background: transparent !important;
  border: none !important;
  text-align: center;
  font-weight: normal;
}

.error-screen-container .action-buttons {
  display: flex;
  gap: 12px;
  justify-content: center;
}

.error-screen-container .primary-button {
  background: transparent !important;
  border: 1px solid #10b2e1 !important;
  color: #10b2e1 !important;
  height: 40px;
  padding: 0 24px !important;
  font-weight: 600 !important;
  transition: all 0.3s ease !important;
  box-shadow: none !important;
}

.error-screen-container .secondary-button {
  background: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  color: rgba(255, 255, 255, 0.8) !important;
  box-shadow: none !important;
}

.error-screen-container .support-info {
  margin-top: 24px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  padding: 16px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.error-screen-container .support-info a {
  color: #10b2e1;
  text-decoration: none;
}
