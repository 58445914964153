/* Card Container */
.cs-result-item__card {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.95) 0%, rgba(101, 210, 216, 0.08) 100%) !important;
  border: 1px solid rgba(101, 210, 216, 0.15) !important;
  border-radius: 12px !important;
  position: relative;
  overflow: hidden;
  margin-bottom: 8px;
  transition: all 0.3s ease;
  backdrop-filter: blur(8px);
}

/* Pearl shine effect */
.cs-result-item__card::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent 0%,
    rgba(101, 210, 216, 0.05) 45%,
    rgba(181, 215, 119, 0.08) 50%,
    rgba(101, 210, 216, 0.05) 55%,
    transparent 100%
  );
  transform: skewX(-15deg);
  animation: pearlShine 8s infinite;
}

@keyframes pearlShine {
  0% {
    transform: translateX(-100%) skewX(-15deg);
  }
  50%,
  100% {
    transform: translateX(100%) skewX(-15deg);
  }
}

/* Card Content */
.cs-result-item__content {
  padding: 16px;
  position: relative;
  z-index: 2;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'header actions'
    'info info';
  gap: 12px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.3) 100%);
}

/* Header Section */
.cs-result-item__header {
  grid-area: header;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.cs-result-item__title {
  color: #1c2536 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  margin: 0;
  line-height: 1.3 !important;
  background: linear-gradient(90deg, #65d2d8, #b5d777) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  position: relative;
}

/* Info Section */
.cs-result-item__info {
  grid-area: info;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cs-result-item__date {
  color: rgba(28, 37, 54, 0.5) !important;
  font-size: 12px !important;
  order: 2;
}

.cs-result-item__metrics {
  display: flex;
  align-items: baseline;
  gap: 8px;
  order: 1;
}

.cs-result-item__current-value {
  color: #1c2536 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

.cs-result-item__change {
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 2px;
  font-weight: 500;
}

.cs-result-item__change--improving {
  color: #b5d777;
}

.cs-result-item__change--worsening {
  color: #ef8d9c;
}

/* Actions */
.cs-result-item__actions {
  grid-area: actions;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
}

.cs-result-item__action-btn {
  color: rgba(28, 37, 54, 0.4) !important;
  transition: all 0.2s ease;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.cs-result-item__action-btn:hover {
  color: #65d2d8 !important;
  background: rgba(101, 210, 216, 0.08) !important;
}

.cs-result-item__detail-btn {
  color: #65d2d8 !important;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-left: 4px;
}

.cs-result-item__detail-btn:hover {
  color: #b5d777 !important;
}

/* Hover effect */
.cs-result-item__card:hover {
  transform: translateY(-1px);
  border-color: rgba(101, 210, 216, 0.3) !important;
  box-shadow: 0 4px 12px rgba(101, 210, 216, 0.08), 0 8px 24px rgba(101, 210, 216, 0.06);
}

/* Ant Design Overrides */
.cs-result-item__card .ant-card-body {
  padding: 0;
}

.cs-result-item__card .ant-typography {
  color: rgba(28, 37, 54, 0.85);
}
