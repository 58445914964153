/* Meta-specific container */
.meta-services-page {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;

  overflow-x: hidden;
}

/* Grid container for buttons */
.meta-services.mobile-quick-buttons {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: repeat(2, 140px);
  gap: 16px 24px;
  justify-content: center;
  padding: 180px 0 160px;
  -webkit-overflow-scrolling: touch;
}

/* Button card styling */
.meta-services .quick-button-card {
  background: linear-gradient(180deg, rgba(16, 178, 225, 0.05) 0%, rgba(16, 178, 225, 0.1) 100%);
  border-radius: 16px;
  padding: 20px;
  height: 140px;
  width: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid rgba(16, 178, 225, 0.15);
  box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.1);
}

.quick-button-card:hover {
  transform: translateY(-2px);
  border-color: rgba(16, 178, 225, 0.3);
}

/* Icon styling */
.quick-button-card .icon-container svg {
  font-size: 36px;
  color: rgba(255, 255, 255, 0.9);
}

/* Title styling */
.quick-button-card .button-title {
  color: #10b2e1;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
}
