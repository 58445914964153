.chronic-meds-container {
  height: 100%;
  overflow: hidden;
  padding-bottom: 16px;
}

.mobile-chronic-meds {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.chronic-meds-list {
  height: auto;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  padding: 16px 16px 24px 16px;
  display: flex;
  gap: 16px;
  align-items: center;
}

.med-card {
  flex: 0 0 280px;
  scroll-snap-align: center;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  gap: 12px;
  border: 1px solid rgba(16, 178, 225, 0.15);
  height: 180px;
  opacity: 0.6;
  transition: all 0.3s ease;
}

.med-card.active {
  opacity: 1;
  transform: scale(1.02);
  border: none;
  position: relative;
}

.med-card.active::before {
  content: '';
  position: absolute;
  inset: -1px;
  border-radius: 16px;
  background: linear-gradient(45deg, #10b2e1, #e91e63);
  z-index: -1;
}

.med-card.active::after {
  content: '';
  position: absolute;
  inset: 1px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.2);
  z-index: -1;
}

.med-icon {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #10b2e1;
}

.med-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 14px;
  height: 100%;
  overflow: hidden;
}

.med-header {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 0;
}

.med-header h3 {
  color: white;
  font-size: 16px;
  margin: 0;
  font-weight: 500;
}

.dosage {
  color: rgba(255, 255, 255, 0.6);
  font-size: 13px;
}

.frequency {
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
  margin: 0;
}

.refill-info {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.days-left {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.days-left .label {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
}

.days-left .value {
  color: white;
  font-size: 16px;
  font-weight: 500;
}

.request-btn {
  background: #e91e63;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 13px;
  font-weight: 500;
  white-space: nowrap;
}

.med-card.urgent {
  border-color: rgba(233, 30, 99, 0.3);
}

.med-card.urgent .days-left .value {
  color: #e91e63;
}

.med-card:active {
  transform: scale(0.98);
}

.chronic-meds-list {
  scroll-behavior: smooth;
}

.med-card:last-child {
  margin-bottom: 0;
}

/* Hide scrollbar */
.chronic-meds-list::-webkit-scrollbar {
  display: none;
}

.chronic-meds-list {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
