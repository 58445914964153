.back-button {
  background: none;
  border: none;
  color: #10b2e1;
  font-size: 20px;
  padding: 8px;
  cursor: pointer;
}

.mobile-medical-history-content {
  flex: 1;
  padding: 16px;
  margin-top: 60px;
  padding-bottom: 80px;
  overflow-y: auto;
}

.progress-bar {
  background: rgba(16, 178, 225, 0.1);
  border-radius: 8px;
  height: 24px;
  margin-bottom: 24px;
  position: relative;
  overflow: hidden;
}

.progress-fill {
  background: #10b2e1;
  height: 100%;
  transition: width 0.3s ease;
}

.progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 12px;
  font-weight: 500;
}

.sections-grid {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr;
}

.section-card {
  border: 1px solid rgba(16, 178, 225, 0.3) !important;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.section-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.section-icon {
  font-size: 24px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(16, 178, 225, 0.1);
  border-radius: 8px;
}

.section-info {
  flex: 1;
}

.section-info h3 {
  margin: 0;
  font-size: 16px;
}

.section-info p {
  margin: 4px 0 0;
  font-size: 12px;
}

.section-status {
  font-size: 20px;
}
