/* Scope all styles to score-overview to prevent global style leaks */
.score-overview {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0;
}

.score-overview h3 {
  color: #fff !important;
  font-size: 20px;
  margin-bottom: 32px;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding-top: 16px;
}

.score-overview .main-score {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  padding: 0 16px;
}

.score-overview .score-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}

.score-overview .score-icon {
  font-size: 28px;
  margin-bottom: 8px;
}

.score-overview .score-value {
  font-size: 28px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 4px;
}

.score-overview .score-label {
  font-size: 16px;
  opacity: 0.7;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.score-overview .score-details {
  margin-top: 0;
  padding: 0 24px;
  text-align: center;
}

.score-overview .score-details .score-status {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 12px;
}

.score-overview .score-details p {
  color: rgba(255, 255, 255, 0.65);
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
}

/* Progress ring customization */
.score-overview .ant-progress-circle .ant-progress-text {
  color: #fff;
  font-weight: 600;
}

.score-overview .ant-progress {
  transform: none;
}
