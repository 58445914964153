.content {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  padding-bottom: 140px;
  background: transparent;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.section {
  margin-bottom: 24px;
  background: transparent;
}

.sectionTitle {
  color: #fff;
  font-size: 16px;
  margin-bottom: 16px;
}

.timeline {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.eta {
  margin: 24px 0;
  padding: 12px;
  background: rgba(16, 178, 225, 0.05);
  border-radius: 12px;
  text-align: center;
}

.statusMessage {
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  font-style: italic;
  margin: 0;
} 