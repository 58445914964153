.test-prep-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 24px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.test-prep-card h4.ant-typography {
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}

.test-prep-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* Duration Styling */
.test-prep-duration {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 8px;
}

.test-prep-duration svg {
  color: #65D2D8;
  font-size: 16px;
}

.test-prep-duration .ant-typography {
  color: rgba(255, 255, 255, 0.85);
  font-size: 14px;
}

/* Requirements List */
.test-prep-requirements {
  margin-bottom: 8px;
}

.requirement-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;
}

/* Icon Styles */
.requirement-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
}

/* Food - Orange */
.food-icon {
  background: rgba(255, 159, 122, 0.1);
}

.food-icon svg {
  color: #FF9F7A;
}

/* Drink - Cyan */
.drink-icon {
  background: rgba(101, 210, 216, 0.1);
}

.drink-icon svg {
  color: #65D2D8;
}

/* Duration - Green */
.duration-icon {
  background: rgba(181, 215, 119, 0.1);
}

.duration-icon svg {
  color: #B5D777;
}

.requirement-item .ant-typography {
  color: rgba(255, 255, 255, 0.65);
  font-size: 14px;
}

/* Notes Section */
.test-prep-notes {
  padding: 12px;
  background: rgba(255, 122, 159, 0.05);
  border-radius: 8px;
  border-left: 3px solid #FF7A9F;
}

.test-prep-notes .ant-typography {
  color: rgba(255, 255, 255, 0.65);
  font-size: 14px;
  line-height: 1.6;
}

/* Mobile Responsiveness */
@media (max-width: 480px) {
  .requirement-icon {
    width: 36px;
    height: 36px;
  }
  
  .requirement-icon svg {
    font-size: 16px;
  }
} 