.form-completion-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  position: relative;
}

.capture-card,
.review-card,
.check-card,
.sign-card,
.submit-card {
  border-radius: 16px;
  padding: 24px;
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(16, 178, 225, 0.15);
  animation: slideUp 0.3s ease-out;
}

.step-icon {
  font-size: 48px;
  color: #10b2e1;
  margin-bottom: 16px;
  animation: bounce 2s infinite;
}

.detected-fields {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 16px 0;
}

.detected-field {
  background: rgba(16, 178, 225, 0.05);
  border: 1px solid rgba(16, 178, 225, 0.3);
  border-radius: 8px;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  animation: slideUp 0.3s ease-out;
}

.check-icon {
  color: #10b2e1;
  font-size: 16px;
}

.field-value {
  color: white;
  font-size: 14px;
  flex: 1;
}

.review-card {
  border-radius: 16px;
  padding: 24px;
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(16, 178, 225, 0.15);
}

.ai-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ai-highlight {
  background: rgba(16, 178, 225, 0.1);
  border: 1px solid #10b2e1;
  border-radius: 4px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: white;
  font-size: 14px;
  animation: fadeIn 0.3s ease-out;
}

.field-item {
  width: 100%;
  background: rgba(16, 178, 225, 0.05);
  border: 1px solid rgba(16, 178, 225, 0.15);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 8px;
  animation: slideUp 0.3s ease-out;
}

.field-item h3 {
  color: white;
  font-size: 16px;
  margin-bottom: 16px;
}

.choice-group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.signature-pad {
  width: 100%;
  height: 200px;
  background: rgba(16, 178, 225, 0.05);
  border: 1px dashed #10b2e1;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.signature-placeholder {
  color: rgba(255, 255, 255, 0.4);
  font-size: 16px;
}

.processing-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  z-index: 100;
}

.processing-icon {
  font-size: 48px;
  color: #10b2e1;
}

.success-animation {
  text-align: center;
}

.success-icon {
  font-size: 64px;
  color: #10b2e1;
  animation: bounceIn 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.email-input {
  width: 100%;
  display: flex;
  gap: 8px;
  margin-top: 16px;
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bounceIn {
  0% {
    transform: scale(0.3);
    opacity: 0;
  }
  50% {
    transform: scale(1.05);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Add new intro styles */
.intro-step {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin-top: 40px;
  position: relative;
  padding-bottom: 80px;
}

.intro-card {
  border-radius: 16px;
  padding: 24px;
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(16, 178, 225, 0.15);
  animation: slideUp 0.3s ease-out;
  margin-bottom: 0;
}

.intro-icons {
  display: flex;
  gap: 16px;
  margin-bottom: 4px;
}

.intro-icon {
  font-size: 32px;
  color: #10b2e1;
}

.intro-icon.robot {
  animation: bounce 2s infinite;
}

.intro-icon.wand {
  animation: bounce 2s infinite 0.3s;
}

.intro-icon.bolt {
  animation: bounce 2s infinite 0.6s;
}

.intro-features {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  background: rgba(16, 178, 225, 0.05);
  border: 1px solid rgba(16, 178, 225, 0.15);
  border-radius: 8px;
  animation: slideRight 0.5s ease-out;
}

.feature-icon {
  color: #10b2e1;
  font-size: 20px;
}

.feature-item p {
  color: white;
  margin: 0;
  text-align: left;
  font-size: 14px;
}

.intro-tagline {
  color: #10b2e1;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin: 0;
  animation: pulse 2s infinite;
}

.get-started-button {
  position: fixed !important;
  bottom: 80px !important;
  left: 16px !important;
  right: 16px !important;
  width: calc(100% - 32px) !important;
  z-index: 10 !important;
}

@keyframes slideRight {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

/* Add these new styles */
.progress-bar {
  width: 100%;
  margin: 8px 0 16px 0;
  text-align: center;
  padding: 0 16px;
}

.progress-text {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  margin-bottom: 12px;
  line-height: 1.2;
}

.progress-dots {
  display: flex;
  justify-content: center;
  gap: 8px;
  padding: 4px 0;
}

.progress-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(16, 178, 225, 0.1);
  border: 1px solid rgba(16, 178, 225, 0.3);
  transition: all 0.3s ease;
}

.progress-dot.active {
  background: #10b2e1;
  transform: scale(1.2);
}

.progress-dot.completed {
  background: rgba(16, 178, 225, 0.5);
}

.check-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 20px;
}

.progress-indicator {
  border-radius: 12px;
  padding: 16px;
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  border: 1px solid rgba(16, 178, 225, 0.15);
  margin-bottom: -8px;
}

.progress-text {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}

.progress-dots {
  display: flex;
  justify-content: center;
  gap: 8px;
  width: 100%;
}

.progress-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(16, 178, 225, 0.1);
  border: 1px solid rgba(16, 178, 225, 0.3);
  transition: all 0.3s ease;
}

.progress-dot.active {
  background: #10b2e1;
  transform: scale(1.2);
}

.progress-dot.completed {
  background: rgba(16, 178, 225, 0.5);
}

.check-header {
  border-radius: 12px;
  padding: 20px;
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  border: 1px solid rgba(16, 178, 225, 0.15);
}

.check-header h2 {
  margin: 0;
  color: white;
  font-size: 24px;
  margin-bottom: 4px;
}

.progress-text {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}

.progress-dots {
  display: flex;
  justify-content: center;
  gap: 8px;
  width: 100%;
}

/* Style for text input questions */
.input-with-continue {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* Update check-step to accommodate three containers */
.check-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 20px;
}

/* Remove h2 from check-card since it's now in its own container */
.check-card {
  padding-top: 16px;
}

.signature-pad-container {
  width: 100%;
  height: 200px;
  background: rgba(16, 178, 225, 0.05);
  border: 1px dashed #10b2e1;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 16px;
  position: relative;
}

.signature-canvas {
  width: 100% !important;
  height: 100% !important;
  touch-action: none;
}

/* Add a subtle hint text */
.signature-pad-container::before {
  content: 'Sign here';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(16, 178, 225, 0.3);
  font-size: 16px;
  pointer-events: none;
  z-index: 0;
}

/* Remove the old signature styles */
.signature-pad,
.signature-placeholder {
  display: none;
}

.selection-step {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-top: 40px;
}

.selection-card {
  border-radius: 16px;
  padding: 24px;
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(16, 178, 225, 0.15);
  animation: slideUp 0.3s ease-out;
}

.selection-card h2 {
  margin: 0;
  padding-top: 8px;
}

.selection-options {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding-top: 8px;
}

.selection-button {
  height: 56px !important;
  background: rgba(16, 178, 225, 0.05) !important;
  border: 1px solid #10b2e1 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 16px !important;
  width: 100% !important;
  color: #10b2e1 !important;
  transition: background-color 0.3s ease !important;
}

.selection-button.selected {
  background: rgba(16, 178, 225, 0.2) !important;
}

.selection-button span {
  color: #10b2e1;
  font-size: 16px;
  text-align: center;
}

.selection-icon {
  font-size: 24px;
  color: #10b2e1;
  min-width: 24px;
}

.hospital-logo {
  height: 32px;
  width: auto;
  object-fit: contain;
}

/* Welcome Text Styling */
.intro-card h2 {
  color: #fff;
  font-size: 24px;
  margin-bottom: 16px;
  font-weight: 600;
}

/* Get Started Button Styling */
.get-started-button {
  background: rgba(16, 178, 225, 0.05) !important;
  border: 1px solid #10b2e1 !important;
  border-radius: 12px !important;
  padding: 16px 32px !important;
  height: auto !important;
  color: #10b2e1 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  cursor: pointer !important;
  display: flex !important;
  align-items: center !important;
  gap: 12px !important;
  transition: all 0.3s ease !important;
  width: calc(100% - 32px) !important;
  max-width: 320px !important;
  justify-content: center !important;
  box-shadow: 0 0 12px rgba(16, 178, 225, 0.2) !important;
}

.get-started-button:hover {
  background: rgba(16, 178, 225, 0.1) !important;
  box-shadow: 0 0 16px rgba(16, 178, 225, 0.4) !important;
  transform: translateY(-1px) !important;
}

.get-started-button svg {
  font-size: 16px;
  color: #10b2e1;
}

/* Card Headers and Text */
.form-completion-container h2 {
  color: #fff;
  font-size: 24px;
  margin-bottom: 16px;
  font-weight: 600;
}

.form-completion-container p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  line-height: 1.5;
}

/* Selection Screen */
.selection-card h2 {
  color: #fff;
  font-size: 24px;
  margin-bottom: 24px;
}

/* Theme Button Styles */
.form-completion-container .theme-button {
  background: rgba(16, 178, 225, 0.05);
  border: 1px solid #10b2e1;
  border-radius: 12px;
  padding: 12px 24px;
  height: auto;
  color: #10b2e1;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
}

.form-completion-container .theme-button:hover {
  background: rgba(16, 178, 225, 0.1);
  box-shadow: 0 0 16px rgba(16, 178, 225, 0.3);
}

.form-completion-container .theme-button.primary {
  background: linear-gradient(135deg, #10b2e1, #1084e1);
  border: none;
  color: #fff;
  font-weight: 600;
}

.form-completion-container .theme-button.primary:hover {
  box-shadow: 0 4px 12px rgba(16, 178, 225, 0.4);
  transform: translateY(-1px);
}

/* Review Screen */
.review-card h2,
.check-card h2,
.sign-card h2,
.submit-card h2 {
  color: #fff;
  font-size: 24px;
  margin-bottom: 24px;
  text-align: center;
}

/* Form Fields */
.form-completion-container .input-field {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 12px;
  color: #fff;
  width: 100%;
}

.form-completion-container .input-field::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

/* Radio Buttons */
.form-completion-container .radio-button {
  background: rgba(16, 178, 225, 0.05);
  border: 1px solid rgba(16, 178, 225, 0.2);
  border-radius: 8px;
  padding: 8px 16px;
  color: #fff;
  transition: all 0.3s ease;
}

.form-completion-container .radio-button.selected {
  background: rgba(16, 178, 225, 0.15);
  border-color: #10b2e1;
}

/* Progress Text */
.check-header .progress-text {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  margin: 8px 0;
}

/* Success Messages */
.success-animation h2 {
  color: #fff;
  font-size: 24px;
  margin: 16px 0 8px;
}

.success-animation p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
}

/* Selection Buttons */
.selection-button {
  background: rgba(16, 178, 225, 0.05) !important;
  border: 1px solid rgba(16, 178, 225, 0.2) !important;
  color: #10b2e1 !important;
  transition: all 0.3s ease !important;
}

.selection-button:hover {
  background: rgba(16, 178, 225, 0.1) !important;
  border-color: #10b2e1 !important;
  box-shadow: 0 0 12px rgba(16, 178, 225, 0.2) !important;
}

.selection-button.selected {
  background: rgba(16, 178, 225, 0.15) !important;
  border-color: #10b2e1 !important;
}

/* Field Labels */
.field-item h3 {
  color: #fff;
  font-size: 16px;
  margin-bottom: 12px;
  font-weight: 500;
}
