.linktop-pulse {
  animation: pulse-linktop 3s infinite;
  border-radius: 50%;
}

@keyframes pulse-linktop {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0px rgba(0, 123, 255, 1);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 20px 10px rgba(0, 123, 255, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  }
}
