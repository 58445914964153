.cancel-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;
}

.cancel-modal {
  background: #141824;
  border-radius: 16px;
  padding: 24px;
  width: 100%;
  max-width: 320px;
  position: relative;
  border: 1px solid rgba(16, 178, 225, 0.15);
}

.cancel-modal::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 16px;
  z-index: -1;
  filter: blur(2px);
}

.cancel-modal-icon {
  width: 48px;
  height: 48px;
  background: rgba(255, 59, 48, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 16px;
  color: #ff3b30;
  font-size: 24px;
}

.cancel-modal h3 {
  color: #fff;
  font-size: 20px;
  text-align: center;
  margin: 0 0 8px 0;
}

.cancel-modal p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  text-align: center;
  margin: 0 0 24px 0;
  line-height: 1.4;
}

.cancel-modal-actions {
  display: flex;
  gap: 12px;
}

.cancel-modal-button {
  flex: 1;
  padding: 12px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
}

.cancel-modal-button.secondary {
  background: rgba(16, 178, 225, 0.1);
  color: #10b2e1;
}

.cancel-modal-button.primary {
  background: rgba(255, 59, 48, 0.1);
  color: #ff3b30;
}

.cancel-modal-button:hover {
  transform: translateY(-1px);
}

.cancel-modal-button.secondary:hover {
  background: rgba(16, 178, 225, 0.15);
}

.cancel-modal-button.primary:hover {
  background: rgba(255, 59, 48, 0.15);
}
