.recommendations-section {
  margin: 16px 24px 120px;
  width: calc(100% - 48px);
  box-sizing: border-box;
}

/* Scope all styles to recommendations-section to prevent global style leaks */
.recommendations-section h3 {
  color: #fff;
  font-size: 18px;
  margin-bottom: 16px;
}

.recommendations-section .recommendation-items {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.recommendations-section .recommendation-item {
  background: rgba(20, 24, 36, 0.6);
  border: 1px solid rgba(16, 178, 225, 0.15);
  border-radius: 16px;
  padding: 16px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  transition: all 0.3s ease;
}

.recommendations-section .recommendation-item:hover {
  background: #1a1f2e;
  border-color: rgba(16, 178, 225, 0.3);
}

.recommendations-section .recommendation-item svg {
  color: #10b2e1;
  font-size: 24px;
  margin-top: 4px;
}

.recommendations-section .recommendation-content {
  flex: 1;
}

.recommendations-section .recommendation-content h4 {
  color: #fff;
  font-size: 16px;
  margin: 0 0 4px 0;
}

.recommendations-section .recommendation-content p {
  color: rgba(255, 255, 255, 0.65);
  font-size: 14px;
  margin: 0 0 12px 0;
}

/* Ant Design Button customization */
.recommendations-section .ant-btn {
  border-color: rgba(16, 178, 225, 0.3);
  color: #10b2e1;
}

.recommendations-section .ant-btn:hover {
  border-color: rgba(16, 178, 225, 0.5);
  color: #10b2e1;
  background: rgba(16, 178, 225, 0.1);
}

/* Health Hacks Section */
.recommendations-section .health-hacks-section {
  margin-top: 32px;
  background: rgba(20, 24, 36, 0.6);
  border-radius: 16px;
  padding: 24px 16px;
  border: 1px solid rgba(16, 178, 225, 0.15);
}

.recommendations-section .section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.recommendations-section .section-header h3 {
  color: #10b2e1;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.recommendations-section .verified-tag {
  background: rgba(46, 204, 113, 0.15);
  color: #2ecc71;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
}

.recommendations-section .products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 16px;
  margin-top: 16px;
}

.recommendations-section .product-card {
  border: 1px solid rgba(16, 178, 225, 0.3);
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 0 20px rgba(16, 178, 225, 0.1), inset 0 0 20px rgba(16, 178, 225, 0.05);
}

.recommendations-section .product-card:hover {
  border-color: rgba(16, 178, 225, 0.8);
  box-shadow: 0 0 25px rgba(16, 178, 225, 0.2), inset 0 0 30px rgba(16, 178, 225, 0.1);
  transform: translateY(-2px);
}

.recommendations-section .product-image {
  position: relative;
  padding-top: 60%;
  background: rgba(20, 24, 36, 0.8);
  border-bottom: 1px solid rgba(16, 178, 225, 0.1);
}

.recommendations-section .placeholder-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('/src/assets/images/landing-image.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.9;
  mix-blend-mode: normal;
}

.recommendations-section .tags {
  display: none;
}

.recommendations-section .product-info {
  padding: 16px;
}

.recommendations-section .brand {
  color: #10b2e1;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 4px;
}

.recommendations-section .product-info h4 {
  color: #fff;
  font-size: 16px;
  margin: 0 0 8px 0;
  font-weight: 600;
}

.recommendations-section .product-info p {
  color: rgba(255, 255, 255, 0.6);
  font-size: 13px;
  margin: 0 0 12px 0;
  line-height: 1.4;
}

.recommendations-section .verified-by {
  color: rgba(46, 204, 113, 0.8);
  font-size: 11px;
  margin-bottom: 16px;
}

.recommendations-section .product-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
}

.recommendations-section .price {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.recommendations-section .add-to-cart-btn {
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1px solid #10b2e1 !important;
  color: #10b2e1 !important;
  height: auto !important;
  padding: 6px 12px !important;
  display: flex !important;
  align-items: center !important;
  gap: 6px !important;
  font-size: 13px !important;
  border-radius: 8px !important;
}

.recommendations-section .add-to-cart-btn:hover {
  background: rgba(16, 178, 225, 0.2) !important;
  transform: translateY(-1px);
}

.recommendations-section .product-card:hover .placeholder-image {
  opacity: 1;
}
