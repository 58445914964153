.uber-virtual {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 1000;
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.back-button {
  position: absolute;
  top: 76px !important;
  left: 16px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1px solid rgba(16, 178, 225, 0.2) !important;
  color: #10b2e1 !important;
  z-index: 1001 !important;
}

.connection-status {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.status-icon {
  font-size: 48px;
  color: #10b2e1;
  margin-bottom: 16px;
}

.connection-status h2 {
  color: white;
  font-size: 24px;
  text-align: center;
  margin: 0;
  min-height: 32px;
}

.join-button-container {
  position: fixed;
  bottom: 68px;
  left: 16px;
  right: 16px;
  z-index: 99;
}

.join-button {
  width: 100% !important;
  height: 56px !important;
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1px solid #10b2e1 !important;
  border-radius: 12px !important;
  color: #10b2e1 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.join-button:hover {
  background: rgba(16, 178, 225, 0.2) !important;
}

.join-button .anticon,
.join-button svg {
  font-size: 18px !important;
  margin-left: 8px !important;
  color: #10b2e1 !important;
}

/* Stage-specific icon styles */
.initiating,
.searching,
.awaiting,
.finalizing,
.preparing {
  opacity: 0.8;
}

.found {
  color: #10b2e1;
}

.confirmed {
  color: #4caf50;
}

.ready {
  color: #10b2e1;
}
