/* Update loading overlay styles */
.loading-overlay {
  position: fixed;
  top: 65px;
  left: 0;
  right: 0;
  bottom: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  color: white;
  padding: 20px;
  width: 100%;
  height: 100%;

  margin-top: -65px;
}

.loading-content svg {
  font-size: 40px;
  color: #10b2e1;
  filter: drop-shadow(0 0 8px rgba(16, 178, 225, 0.3));
  margin-bottom: 8px;
}

.loading-content span {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: rgba(255, 255, 255, 0.9);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes flipHorizontal {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.typing-animation {
  border-right: 2px solid #000;
  animation: typing 1s steps(40) infinite, blink-caret 0.75s step-end infinite;
  white-space: nowrap;
  overflow: hidden;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #000;
  }
}
