.test-benefits-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 24px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.test-benefits-card:hover {
  background: rgba(255, 255, 255, 0.08);
  border-color: rgba(255, 255, 255, 0.15);
}

.test-benefits-card h4.ant-typography {
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}

.test-detail-benefits {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.test-detail-benefits li {
  display: flex;
  align-items: center;
  gap: 12px;
  color: rgba(255, 255, 255, 0.65);
  line-height: 1.6;
  margin-bottom: 12px;
}

.test-detail-benefits li:last-child {
  margin-bottom: 0;
}

.benefit-icon {
  color: #65D2D8;
  font-size: 14px;
  flex-shrink: 0;
  margin-top: 0;
  display: flex;
  align-items: center;
}

.benefit-text {
  font-size: 14px;
  flex: 1;
  padding-top: 1px;
}

/* Animation for hover effect */
.test-detail-benefits li:hover .benefit-icon {
  transform: scale(1.1);
  transition: transform 0.2s ease;
}

.test-detail-benefits li:hover .benefit-text {
  color: rgba(255, 255, 255, 0.85);
  transition: color 0.2s ease;
} 