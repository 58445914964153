.searchInput {
  border: none;
  width: 100%;
}
.searchInput:focus {
  outline: none;
}
.searchContainer {
  border: 1px #cccccca4 solid;
  display: grid;
  grid-template-columns: 24px auto;
  gap: 10px;
  border-radius: 5px;
  box-sizing: border-box;
}
.searchContainer > * {
  padding: 10px;
  box-sizing: border-box;
}
.test {
  color: #dadada;
}

/* CUSTOM BUTTON */
.custom-button {
  box-shadow: 0 0 5px 0 transparent;
  transition: 250ms ease;
}

.custom-button:hover {
  cursor: pointer;
  opacity: 0.8;
  box-shadow: 0 0 5px 0 #ccc;
}

/* NEXT APPOINTMENT BANNER */
.next-appointment-banner-container {
  border-radius: 12px;
  padding: 8px;
  background: #f14f8a;
  display: flex;
  gap: 12px;
  align-items: center;
}
.next-appointment-date {
  padding: 12px;
  display: flex;
  height: 75px;
  width: 75px;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: #ffffff;
}
@media (max-width: 650px) {
  .next-appointment-banner-container {
    flex-direction: column;
  }
  .next-appointment-date {
    width: 100%;
  }
}

.feature-button-container {
  padding: 4px;
  border-radius: 8px;
}

.feature-button {
  padding: 8px 16px;
  max-width: 100%;
  min-width: 250px;
  background: transparent;
  border-radius: 8px;
  color: white;
  border: 2px white solid;
  cursor: pointer;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes flash {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
}

.custom-indicator-flash {
  width: 72px;
  height: 72px;
  animation: flash 1s linear infinite;
}
