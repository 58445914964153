.payment-success-container {
  min-height: calc(100vh - 120px);
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.payment-success-container .success-card {
  background: rgba(20, 24, 36, 0.95);
  border: 1px solid rgba(16, 178, 225, 0.15);
  border-radius: 12px;
  padding: 32px;
  width: 100%;
  max-width: 500px;
  text-align: center;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  margin-top: -120px;
}

/* Override ant design result styles */
.payment-success-container .ant-result {
  padding: 0;
}

.payment-success-container .ant-result-icon {
  margin-bottom: 24px;
}

.payment-success-container .ant-result-title {
  display: none;
}

.payment-success-container .ant-result-subtitle {
  color: rgba(255, 255, 255, 0.6);
  font-family: monospace;
  margin-top: 8px;
}

/* Animated checkmark */
.payment-success-container .success-icon-wrapper {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto 24px;
  background: transparent;
}

.payment-success-container .success-circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 3px solid #10b2e1;
  border-radius: 50%;
  animation: circle 0.8s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  opacity: 0;
  background: transparent;
}

.payment-success-container .success-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  color: #10b2e1;
  animation: checkmark 0.8s cubic-bezier(0.65, 0, 0.45, 1) 0.2s forwards;
  opacity: 0;
}

@keyframes circle {
  0% {
    transform: scale(0.6);
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes checkmark {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

/* Transaction details */
.payment-success-container .transaction-details {
  background: rgba(16, 178, 225, 0.1);
  border-radius: 8px;
  padding: 16px;
  margin: 24px 0;
  text-align: left;
}

.payment-success-container .detail-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
}

.payment-success-container .detail-item:last-child {
  margin-bottom: 0;
}

.payment-success-container .detail-label {
  color: rgba(255, 255, 255, 0.5);
}

/* Email confirmation notice */
.payment-success-container .confirmation-notice {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  margin: 16px 0;
}

/* Buttons */
.payment-success-container .action-buttons {
  display: flex;
  gap: 12px;
  justify-content: center;
  margin-top: 24px;
  background: transparent !important;
}

.payment-success-container .primary-button {
  background: transparent !important;
  border: 1px solid #10b2e1 !important;
  color: #10b2e1 !important;
  height: 40px;
  padding: 0 24px !important;
  font-weight: 600 !important;
  transition: all 0.3s ease !important;
  box-shadow: none !important;
}

.payment-success-container .primary-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 15px rgba(16, 178, 225, 0.2), 0 0 5px rgba(16, 178, 225, 0.1);
  border-color: rgba(16, 178, 225, 0.8) !important;
}

.payment-success-container .secondary-button {
  background: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  color: rgba(255, 255, 255, 0.8) !important;
  box-shadow: none !important;
}

/* Add specific heading style */
.payment-success-container .success-card h2 {
  color: #fff;
  font-size: 24px;
  margin-bottom: 24px;
  font-weight: 600;
}
