.mobile-profile-container {
  height: 100vh;

  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 70px;
  overflow: hidden;
}

.profile-content {
  flex: 1;
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 140px;
  padding-top: 10px;
}

.profile-image-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 auto;
}

.profile-info-section {
  padding: 0 16px;
  margin-bottom: 16px;
}

.profile-action-section {
  position: fixed;
  bottom: 70px;
  left: 0;
  right: 0;
  padding: 16px;
  background: transparent;
  z-index: 10;
}

.profile-image-container {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #10b2e1;
  box-shadow: 0 4px 12px rgba(16, 178, 225, 0.2);
  z-index: 2;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 5px;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info-container {
  width: 100%;
  padding: 0 16px;
  margin-top: 12px;
}

.info-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.info-section::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 16px;
  background: linear-gradient(
    145deg,
    rgba(255, 255, 255, 0.15) 0%,
    rgba(255, 255, 255, 0.05) 50%,
    rgba(255, 255, 255, 0.02) 100%
  );
  z-index: 0;
  filter: blur(2px);
}

.info-section::after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.info-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.info-icon-row {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.info-icon {
  color: #10b2e1;
  font-size: 18px;
  margin-right: 8px;
}

.info-label {
  font-size: 12px;
}

.info-value {
  font-size: 14px;
  font-weight: 500;
}

.profile-header {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.mobile-edit-button {
  position: fixed;
  bottom: 90px;
  left: 16px;
  right: 16px;
  height: 48px;
  background: rgba(16, 178, 225, 0.1);
  border: 1px solid #10b2e1;
  border-radius: 8px;
  color: #10b2e1;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  z-index: 100;
}

.mobile-edit-button:hover,
.mobile-edit-button:focus {
  background: rgba(16, 178, 225, 0.2) !important;
  border-color: #10b2e1 !important;
  color: #10b2e1 !important;
}

.mobile-edit-button svg {
  font-size: 16px;
}

.edit-button-container,
.edit-button,
.edit-icon-button {
  display: none;
}
